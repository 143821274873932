

import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Rnd } from 'react-rnd';
import axios from 'axios';
import RecordRTC from 'recordrtc';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './InterviewScreen.css';
import { initializeSpeechRecognizer, startContinuousRecognition } from '../utils/longSpeechToText';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { v4 as uuidv4 } from 'uuid';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import * as tf from '@tensorflow/tfjs';
// eslint-disable-next-line
import * as faceapi from '@vladmandic/face-api';
import * as poseDetection from '@tensorflow-models/pose-detection';


let combinedStream = null;
let mergedAudioStream = null;
let audioTracks = null;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isIphone = /iPhone/i.test(navigator.userAgent);
const isAndroid = /Android/i.test(navigator.userAgent);



const InterviewScreen = ({ interviewId, candidateId, interviewIdurl }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    const [isListening, setIsListening] = useState(false);
    const [interviewStarted, setInterviewStarted] = useState(false);
    const [interviewEnded, setInterviewEnded] = useState(false);
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    const synthesizerRef = useRef(null);
    const recognizerRef = useRef(null);
    const recorderRef = useRef(null);
    const [CurrentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const navigate = useNavigate();
    const currentquestionstr = useRef("");
    const questionStartTm = useRef(new Date());
    const responseStartTm = useRef(new Date());
    const currentTextstr = useRef("");
    const segmentationRef = useRef(null);
    const [backgroundOption, setBackgroundOption] = useState('none');
    const [avatarbackgroundColor, setAvatarbackgroundColor] = useState('white');
    const [languageOption, setLanguageOption] = useState('none');
    const [avatarimageOption, setAvatarimageOption] = useState('Avatar.png');
    const [isWebcamProblem, setIsWebcamProblem] = useState(false); // Track support for getUserMedia Webcam
    const [userCompany, setUserCompany] = useState(0);
    
    currentTextstr.current = "When you're ready, click 'Start Interview'. The AI Interviewer will ask a series of questions and wait for your response, indicated by a flashing RED icon. Please answer each question as thoroughly as possible. After 5 SECONDS of silence, the interviewer will automatically proceed to the next question. You may end the interview at any time by clicking 'End Interview'."
    const subscriptionKey = '49cfae0357764d2b86ff759b7f2eeeb9'; // this is for AIInterview2
    const serviceRegion = 'centralus';
    // const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
    // let speechConfig;

    const [modelsReady, setModelsReady] = useState(false);
    const [poseDetector, setPoseDetector] = useState(null);
    const currentAnalysis = useRef("");
    const [speechConfig, setspeechConfig] = useState(null);
    const [showGuide, setShowGuide] = useState(false); // State to manage the guide visibility
    const [countdownActive, setCountdownActive] = useState(false);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const toastIdRef = useRef(null);
    

    const notify = () => {
        toast.error("Silence detected! Moving to next question in 3", {
          position: "top-right",
          autoClose: 3000, // Toast automatically closes after 3 seconds
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored", // Can be "light", "dark", or "colored"
        });
      };



    useEffect(() => {

        const loadSegmentation = async () => {
            segmentation.onResults(onResults);
            segmentationRef.current = segmentation;
            if (webcamRef.current && webcamRef.current.video.readyState === 4) {
                segmentVideo();
            } else {
                webcamRef.current.video.onloadeddata = () => {
                    segmentVideo();
                };
            }
        };
        loadSegmentation();


        return () => {
            if (webcamRef.current && webcamRef.current.video) {
                webcamRef.current.video.onloadeddata = null;
            }
        };

    }, [backgroundOption]);

    const segmentVideo = async () => {
        if (!webcamRef.current || !segmentationRef.current) {
            return;
        }
        const { video } = webcamRef.current;
        segmentationRef.current = segmentation;
        await segmentationRef.current.send({ image: video }); // this line gives error on changing if one video effect is running 


        requestAnimationFrame(segmentVideo);
    };

    const onResults = async (results) => {
        if (!canvasRef.current || !webcamRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (canvas.width === 0) {
            canvas.width = results.image.width;
            canvas.height = results.image.height;

        }


        switch (backgroundOption) {
            case 'none':
                context.save();
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
                break;

            case 'blurred':
                context.save();
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
                context.globalCompositeOperation = 'source-in';
                context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
                context.globalCompositeOperation = 'destination-atop';
                context.filter = 'blur(6px)';
                context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
                //context.restore();
                break;

            case 'office1':
            case 'office2':
            case 'beach':
                context.drawImage(backgroundImageRefs.webcambackground.current, 0, 0, canvas.width, canvas.height);
                
                // Draw the segmentation mask
                context.globalCompositeOperation = 'destination-out';
                context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);

                // Draw the original image on top to keep the person clear
                context.globalCompositeOperation = 'destination-atop';
                context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
                // Reset the global composite operation
                context.globalCompositeOperation = 'source-over';
                break;

            default:
                context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
                break;
        }

        context.restore();
    };



    useEffect(() => {
        const preloadModels = async () => {

            try {
                // console.log("Initializing TensorFlow.js backend...");
                await tf.setBackend("webgl");
                await tf.ready();

                const MODEL_URL = "https://cdn.jsdelivr.net/gh/vladmandic/face-api/model/";
                await Promise.all([
                    faceapi.nets.tinyFaceDetector.loadFromUri(`${MODEL_URL}tiny_face_detector_model-weights_manifest.json`),
                    faceapi.nets.faceExpressionNet.loadFromUri(`${MODEL_URL}face_expression_model-weights_manifest.json`),
                    faceapi.nets.faceLandmark68Net.loadFromUri(`${MODEL_URL}face_landmark_68_model-weights_manifest.json`),
                ]);
                const detector = await poseDetection.createDetector(poseDetection.SupportedModels.MoveNet);
                setPoseDetector(detector); // Store in state

                setModelsReady(true); // Indicate that all models are ready
            } catch (error) {
                console.error("Error preloading models:", error);
            }
        };

        preloadModels();
    }, []);


    useEffect(() => {
        const checkWebcam = async () => {
            const isSupported = await checkWebcamSupport();
            if (!isSupported) {
                // console.log("Webcam not supported or accessible.");
                setIsWebcamProblem(true); // true means webcam is a problem. should disable start interview button
            } else {
                // console.log("Webcam is supported and accessible.");
                setIsWebcamProblem(false);

            }
        };
        checkWebcam();
    }, []);


    const checkWebcamSupport = async () => {
        // Check if getUserMedia is supported
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert("Webcam is not supported on your browser. Please update your browser or use a modern one like Chrome or Safari.");
            return false;
        }

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert("Your browser does not support microphone access. Please use a modern browser like Chrome.");
            return false;
        }

        try {
            // Attempt to access the webcam
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });

            // Stop the video stream immediately after checking
            stream.getTracks().forEach((track) => track.stop());

            return true; // Webcam is supported and accessible
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                alert("Access to the webcam is blocked. Please allow webcam access in your browser settings.");
            } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
                alert("No webcam detected. Please ensure your webcam is connected.");
            } else {
                alert("Unable to access the webcam. Please try again or update your browser.");
            }
            return false;
        }
    };

    const requestSpeechPermission = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                console.error("getUserMedia is not supported in this browser.");
                return resolve(false); // Resolve as `false` if not supported
            }
    
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(() => {
                    console.log("Microphone permission granted.");
                    resolve(true);
                })
                .catch((error) => {
                    console.error("Microphone permission denied:", error);
                    resolve(false); // Resolve as `false` in case of failure
                });
        });
    };


    // // Initialize Speech Synthesizer
    const initializeSpeechSynthesizer = (voicename) => {

        try {
            speechConfig.speechSynthesisVoiceName = voicename || "en-US-AndrewMultilingualNeural"; // Default voice if none passed

            if (isIphone) {
                // For iPhone, use PullAudioOutputStream
                const audioStream = SpeechSDK.AudioOutputStream.createPullStream();
                const audioConfig = SpeechSDK.AudioConfig.fromStreamOutput(audioStream);
                const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
                console.log("Speech Synthesizer initiated for iPhone")
                return { synthesizer, audioStream }; // Return audioStream for manual playback
            } else if (isAndroid) {
                // Handle permission for Android
                requestSpeechPermission().then((hasPermission) => {
                    if (!hasPermission) {
                        console.error("Speech initialization aborted due to missing microphone permissions.");
                        return null;
                    }
        
                    // Proceed with Android-specific initialization
                    const player = new SpeechSDK.SpeakerAudioDestination();
                    const audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
                    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
                    console.log("Speech Synthesizer initiated for Android in a browser");
                    return { synthesizer, player };
                });
            } else {
                // Default behavior for non-iPhone
                const player = new SpeechSDK.SpeakerAudioDestination();
                const audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
                const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
                console.log("Speech Synthesizer initiated for non-iPhone")
                return { synthesizer, player };
            }
        } catch (error) {
            console.error("Failed to initialize speech synthesizer:", error);
            return null;
        }
    };

    // Function to Speak Text
    const speakText = (synthesizerObj, text, onComplete) => {
        if (!synthesizerObj) {
            console.error("Invalid synthesizer object.");
            if (onComplete) onComplete();
            return;
        }

        const { synthesizer, player, audioStream } = synthesizerObj;

        const playAudioFromStream = async (audioArrayBuffer, onComplete) => {
            try {
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const audioBuffer = await audioContext.decodeAudioData(audioArrayBuffer);
                const source = audioContext.createBufferSource();
                source.buffer = audioBuffer;
                source.connect(audioContext.destination);

                // Start playback
                source.start();

                // Invoke `onComplete` when playback ends
                source.onended = () => {
                    if (onComplete) onComplete();
                };
            } catch (error) {
                console.error("Web Audio API playback failed:", error);
                if (onComplete) onComplete(); // Ensure onComplete is called even in case of errors
            }
        };

        try {
            synthesizer.speakTextAsync(
                text,
                async (result) => {
                    if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                        const audioData = result.audioData; // Raw audio data

                        const ttsAudioBlob = isSafari
                        ? (isIphone
                            ? new Blob([result.audioData], { type: "audio/aac" }) // iPhone-specific format
                            : new Blob([result.audioData], { type: "audio/mpeg" })) // Safari-compatible format
                        : (isAndroid
                            ? new Blob([result.audioData], { type: "audio/mpeg" }) // Android prefers audio/mpeg
                            : new Blob([result.audioData], { type: "WebM" })); // Default format for other devices

                        setTTSBlob(ttsAudioBlob);

                        if (isIphone || isAndroid) {
                            // Use Web Audio API for playback on iPhones
                            console.log("Using Web Audio API for iPhone.");
                            const audioArrayBuffer = new Uint8Array(result.audioData).buffer;

                            playAudioFromStream(audioArrayBuffer, onComplete); // Pass `onComplete` to be called after playback
                        } else {
                            // Non-Safari browsers use player directly
                            player.onAudioEnd = () => {
                                if (onComplete) onComplete();
                            };
                        }

                    } else {
                        console.error("Speech synthesis error:", result.errorDetails);
                        if (onComplete) onComplete();
                    }
                    synthesizer.close(); // Close the synthesizer
                },
                (err) => {
                    console.error("Speech synthesis failure:", err);
                    if (onComplete) onComplete();
                    synthesizer.close();
                }
            );
        } catch (error) {
            console.error("Error during speech synthesis:", error);
            if (onComplete) onComplete();
        }
    };



    const backgrounds = [
        'white',
        'navy',
        'slategray',
        'darkslateblue',
        'midnightblue',
        'teal',
        'olive',
        'beige',
        'lavender',
        'coral',
        'firebrick',
        'darkgreen',
        'steelblue',
        'saddlebrown',
        'darkolivegreen',
        'dimgray',
        'lightslategray',
        'indigo',
        'mediumorchid',
        'rebeccapurple',
        'slateblue'
    ];

    const backgroundImageRefs = {
        webcambackground: useRef(new Image())
    };

    useEffect(() => {
        const updateBackgroundImage = () => {
            if (backgroundOption !== 'none' && backgroundOption !== 'blurred') {
                backgroundImageRefs.webcambackground.current.src = `${backgroundOption}.webp`;
                backgroundImageRefs.webcambackground.current.onload = () => {
                    // console.log(`${backgroundOption} background image successfully loaded`);
                };
                backgroundImageRefs.webcambackground.current.onerror = () => {
                    console.error(`Failed to load the background image: ${backgroundOption}`);
                };
            } else {
                backgroundImageRefs.webcambackground.current.src = ''; // Clear the source if 'none' or 'blurred'
            }
        };

        updateBackgroundImage();
    }, [backgroundOption]);




    const segmentation = new SelfieSegmentation({ locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}` });
    segmentation.setOptions({
        modelSelection: 1,
    });


    const handleStartInterview = async () => {

        if (isSafari && !isIphone) {
            alert(
                "Click Close to start the interview.\n\n"
            );

        }

        setspeechConfig(SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion));



        setInterviewStarted(true);
        try {
            currentquestionstr.current = "Ready for your interview? Please wait..."
            const response = await axios.get(`${config.apiBaseUrl}/api/initial_data/${interviewId}/`);
            console.log("Response from initial data:", response.data);

            setUserCompany(response.data.usercompany)
            setTotalQuestions(response.data.total_questions);
            setCurrentQuestionNumber(CurrentQuestionNumber + 1);
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    const startInterview = async () => {

        if (interviewStarted) {
            setIsWaiting(true);
            console.log("languageOption", languageOption)
            synthesizerRef.current = initializeSpeechSynthesizer(languageOption);
            recognizerRef.current = initializeSpeechRecognizer();
            const questionData = await fetchQuestionInstruction(interviewId);
            // currentquestionstr.current = questionData.aiuser_instruction;

            currentquestionstr.current = `${CurrentQuestionNumber}/${totalQuestions}: ${questionData.aiuser_instruction}`;

            currentTextstr.current = questionData.aiuser_instruction;
            questionStartTm.current = questionData.current_time
            responseStartTm.current = questionStartTm.current
            setIsWaiting(false);

            if (questionData.action_id === 1)   // 1 means its just a text to be spoken
            {
                await processQuestion(questionData.aiuser_instruction, questionData.action_id, handleOnComplete);
            }

            if (questionData.action_id === 2)   // 2 means its a question and will listen for response
            {
                await processQuestion(questionData.aiuser_instruction, questionData.action_id, handleOnComplete);
            }

            if (questionData.action_id === 3)   // 3 means the program will use last candidate response and get appropriate response from OpenAI
            {
                const tempresponseText = await callChatAPI(questionData.aiuser_instruction);
                await processQuestion(tempresponseText, questionData.action_id, handleOnComplete);

            }

            if (questionData.action_id === 100) {
                setInterviewEnded(true)
                try {
                    const response = await axios.post(`${config.apiBaseUrl}/api/end_completed/${interviewId}/`);
                    combinedStream.getTracks().forEach(track => track.stop());
                    combinedStream = null;

                    navigate('/thank-you'); // Redirect to thank you page
                } catch (error) {
                    console.error("Error ending interview:", error);
                }

            }
        }
        else {
        }

    };


    const endInterviewandNavigate = async () => {
        setInterviewEnded(true)
        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/end_completed/${interviewId}/`);
            combinedStream.getTracks().forEach(track => track.stop());
            combinedStream = null;

            navigate('/thank-you'); // Redirect to thank you page
        } catch (error) {
            console.error("Error ending interview:", error);
        }
    
    }


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const initiateInterview = async () => {
            try {
                setIsSpeaking(false);
                setIsListening(false);
                setIsWaiting(false);



                if (!interviewStarted) {
                    const response = await axios.get(`${config.apiBaseUrl}/api/get-avatar/${interviewId}/`);
                    // Handle the response data
                    const avatarData = response.data;
                    setAvatarimageOption(avatarData.face_image)
                    setAvatarbackgroundColor(backgrounds[avatarData.background])
                    setLanguageOption(avatarData.voice)
                }

                await startInterview();
            } catch (error) {
                console.error(error);
            }
        };
        initiateInterview();
    }, [CurrentQuestionNumber]);

    const processQuestion = async (questionText, actionidd, handleOnComplete) => {
        setIsWaiting(false);
        setIsSpeaking(true);
        // Start recording
        if (canvasRef.current && webcamRef.current) {


            if (actionidd === 1) { // 
                await speakText(synthesizerRef.current, questionText, () => {
                    setIsSpeaking(false);

                    if (handleOnComplete) {
                        handleOnComplete("Response not expected");
                    }
                }, setTTSBlob);
            } else {
                await speakText(synthesizerRef.current, questionText, () => {
                    setIsListening(true);
                    setIsSpeaking(false);
                    startContinuousRecognition(recognizerRef.current, handleOnComplete, setCountdown);
                }, setTTSBlob);
            }

        }
    };


    // async function inspectAudioBlob(audioBlob) {
    //     // Print basic Blob properties
    //     console.log('Blob size:', audioBlob.size, 'bytes');
    //     console.log('Blob type:', audioBlob.type);

    //     // Create an object URL to load the Blob as an audio element
    //     const audioURL = URL.createObjectURL(audioBlob);

    //     // Create an AudioContext (this should be defined outside the onloadedmetadata event)
    //     const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    //     // Create an audio element to get basic audio metadata
    //     const audio = new Audio();
    //     audio.src = audioURL;

    //     // Wait until the metadata is loaded
    //     audio.onloadedmetadata = async () => {
    //         console.log('Audio duration:', audio.duration, 'seconds');

    //         try {
    //             // Use the Web Audio API to decode the audio and get more detailed information
    //             const arrayBuffer = await audioBlob.arrayBuffer();
    //             const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    //             // Print detailed audio properties
    //             console.log('Number of channels:', audioBuffer.numberOfChannels);
    //             console.log('Sample rate:', audioBuffer.sampleRate, 'Hz');
    //             console.log('Duration (from buffer):', audioBuffer.duration, 'seconds');

    //             for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
    //                 console.log(`Channel ${i + 1} data length:`, audioBuffer.getChannelData(i).length);
    //             }
    //         } catch (error) {
    //             console.error('Error decoding audio data:', error);
    //         }
    //     };

    //     // Handle error in loading the audio metadata
    //     audio.onerror = (e) => {
    //         console.error('Error loading audio metadata:', e);
    //     };
    // }


    const setTTSBlob = async (audioBlob) => {
        try {
            // console.log("Inside setTTSBlob");
            let recorder = null;
            const canvasStream = canvasRef.current.captureStream(30);
            audioTracks = webcamRef.current.stream.getAudioTracks();
    
            mergedAudioStream = await mergeAudioStreams(audioBlob, audioTracks);
    
            if (isIphone) {
                // iPhone-specific behavior
                combinedStream = new MediaStream([
                    ...canvasStream.getTracks(),
                    ...mergedAudioStream.getAudioTracks(),
                ]);
            } else if (isAndroid) {
                // Android-specific behavior
                combinedStream = new MediaStream([
                    ...canvasStream.getTracks(),
                    ...mergedAudioStream.getAudioTracks(),
                ]);
                console.log("Recording in Android");
                recorder = new RecordRTC(combinedStream, {
                    type: "video",
                    mimeType: "video/mp4", // Use MP4 for better Android compatibility
                    videoBitsPerSecond: 2000000, // Adjust bitrate for Android devices
                    audioBitsPerSecond: 128000,
                    disableLogs: true,
                });
            } else {
                // Non-iPhone/Android behavior
                const srcAvatar = avatarimageOption; // Path to your image file
                const imageCombinedStream = await streamImageInCornerAndMerge(srcAvatar, canvasStream);
                combinedStream = new MediaStream([
                    ...imageCombinedStream.getTracks(),
                    ...mergedAudioStream.getAudioTracks(),
                ]);
                recorder = new RecordRTC(combinedStream, {
                    type: "video",
                    mimeType: "video/webm",
                    videoBitsPerSecond: 2500000,
                    audioBitsPerSecond: 128000,
                    disableLogs: true,
                });
            }
    
            recorder.startRecording();
            recorderRef.current = recorder;

//             if (!isIphone) {
//                 // startRealTimeAnalysis(combinedStream);
//             }


        } catch (error) {
            console.error("Error while setting TTS Blob:", error);
        }
    };

    const startRealTimeAnalysis = async (stream) => {
        try {
            if (!modelsReady || !poseDetector) {
                console.warn("Models not ready yet.");
                return;
            }
            let analysisStarted = false; // Status variable to track if analysis has started

            currentAnalysis.current = []; // Reset for a new session

            // Setup video stream
            // console.log("Current Time1:", new Date().toLocaleTimeString());

            // console.log("Setting up video stream...");
            const video = document.createElement("video");
            video.srcObject = stream;
            video.muted = true;

            await video.play();



            // Setup audio analysis
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;
            const audioDataArray = new Uint8Array(analyser.frequencyBinCount);

            // Start the real-time analysis loop
            console.log("Starting real-time analysis...");
            let analysisLoop = setInterval(async () => {
                try {

                    if (analysisStarted) { // Check exit conditions only after analysis has started
                        if (!stream.active || stream.getTracks().some(track => track.readyState !== 'live')) {
                            console.log("****************Stopping analysis loop due to stream end.************************");
                            clearInterval(analysisLoop);
                            video.pause();
                            video.srcObject = null;
                            stream.getTracks().forEach(track => track.stop());
                            return;
                        }
                    }

                    analysisStarted = true; // Set to true after the first loop iteration

                    // **Visual Analysis**
                    const detections = await faceapi
                        .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                        .withFaceLandmarks()
                        .withFaceExpressions();


                    const emotions = detections.map((d) => d.expressions || {});
                    const gazeDirection = calculateGazeDirection(detections); // Ensure this utility exists
                    console.log("Gaze Direction:", gazeDirection);

                    const posture = await detectPosture(poseDetector, video); // Ensure this utility exists
                    // console.log("Current Time5a:", new Date().toLocaleTimeString());

                    // **Behavioral Analysis**
                    const microexpressions = detectMicroexpressions(detections); // Ensure this utility exists
                    const gestures = await detectGestures(poseDetector, video); // Ensure this utility exists
                    // console.log("Current Time5b:", new Date().toLocaleTimeString());

                    // **Voice Analysis**
                    analyser.getByteFrequencyData(audioDataArray);
                    const volume = audioDataArray.reduce((a, b) => a + b, 0) / audioDataArray.length; // Average volume
                    const pitch = Math.max(...audioDataArray); // Peak frequency amplitude
                    // console.log("Current Time5c:", new Date().toLocaleTimeString());

                    // **Engagement Metrics**
                    const engagementScore = calculateEngagement(emotions, gestures); // Ensure this utility exists
                    // console.log("Current Time6:", new Date().toLocaleTimeString());

                    // Prepare analysis data
                    const analysisData = {
                        visual: { emotions, gazeDirection, posture },
                        behavioral: { microexpressions, gestures },
                        // voice: { pitch, volume },
                        engagement: { score: engagementScore },
                    };
                    // console.log("Current Time7:", new Date().toLocaleTimeString());


                    // console.log(analysisData)
                    // Append analysis data
                    currentAnalysis.current.push(analysisData);
                    // console.log(`***************Stream active status: ${stream.active}`);


                } catch (error) {
                    console.error("Error during analysis loop:", error);
                }
            }, 5000); // Analyze every 800ms

            // Cleanup resources on stream end
            const stopAnalysis = () => {
                console.log("Inside Stopping analysis loop.");
                clearInterval(analysisLoop);
                video.pause();
                video.srcObject = null; // Clear the video source
                stream.getTracks().forEach((track) => track.stop());
                console.log("Stream ended. Stopping analysis loop.");
            };

        } catch (error) {
            console.error("Error initializing real-time analysis:", error);
        }
    };




    const calculateGazeDirection = (detections) => {
        if (!detections || detections.length === 0) {
            return "away"; // No face detected
        }

        const detection = detections[0]; // Assume single face for now
        const landmarks = detection.landmarks;
        if (!landmarks) {
            return "away";
        }

        const leftEye = landmarks.getLeftEye();
        const rightEye = landmarks.getRightEye();
        const nose = landmarks.getNose();
        const jaw = landmarks.getJawOutline();

        if (!leftEye || !rightEye || !nose || !jaw) {
            return "away"; // Missing key landmarks
        }

        // Calculate midpoints and angles
        const eyeMidPoint = {
            x: (leftEye[0].x + rightEye[3].x) / 2,
            y: (leftEye[0].y + rightEye[3].y) / 2,
        };
        const nosePoint = nose[3]; // Approximate tip of the nose
        const jawPoint = jaw[Math.floor(jaw.length / 2)]; // Center of the jawline

        // Calculate vertical and horizontal displacement
        const horizontalDisplacement = eyeMidPoint.x - nosePoint.x;
        const verticalDisplacement = eyeMidPoint.y - jawPoint.y;

        // Thresholds for determining directions
        const horizontalThreshold = 10; // Adjust based on testing
        const verticalThreshold = 10;

        // Determine gaze direction based on displacement
        if (horizontalDisplacement > horizontalThreshold) {
            return "right";
        } else if (horizontalDisplacement < -horizontalThreshold) {
            return "left";
        } else if (verticalDisplacement > verticalThreshold) {
            return "up";
        } else if (verticalDisplacement < -verticalThreshold) {
            return "down";
        } else {
            return "center"; // Default to center
        }
    };



    // const detectPosture = async (poseDetector, video) => {
    //     const poses = await poseDetector.estimatePoses(video);
    //     return poses.length > 0 && poses[0].keypoints[11].y - poses[0].keypoints[12].y > 20
    //         ? "upright"
    //         : "slouched";
    // };


    const detectPosture = async (poseDetector, video) => {
        const poses = await poseDetector.estimatePoses(video);

        if (!poses || poses.length === 0) {
            return "undetected"; // No pose detected
        }

        const keypoints = poses[0].keypoints; // Assume single person for now
        const leftShoulder = keypoints.find((point) => point.name === "left_shoulder");
        const rightShoulder = keypoints.find((point) => point.name === "right_shoulder");
        const leftHip = keypoints.find((point) => point.name === "left_hip");
        const rightHip = keypoints.find((point) => point.name === "right_hip");

        if (!leftShoulder || !rightShoulder || !leftHip || !rightHip) {
            return "incomplete"; // Missing keypoints
        }

        // Calculate vertical alignment of shoulders and hips
        const shoulderAlignment = Math.abs(leftShoulder.y - rightShoulder.y); // Difference in y-coordinates
        const hipAlignment = Math.abs(leftHip.y - rightHip.y);

        // Calculate horizontal alignment (leaning)
        const shoulderLean = leftShoulder.x - rightShoulder.x;
        const hipLean = leftHip.x - rightHip.x;

        // Determine posture based on thresholds
        const verticalThreshold = 20; // Adjust for sensitivity
        const horizontalThreshold = 15;

        if (shoulderAlignment > verticalThreshold) {
            return "hunched"; // Shoulders misaligned vertically
        } else if (hipAlignment > verticalThreshold) {
            return "reclined"; // Hips misaligned vertically
        } else if (shoulderLean > horizontalThreshold) {
            return "leaning left"; // Shoulders leaning left
        } else if (shoulderLean < -horizontalThreshold) {
            return "leaning right"; // Shoulders leaning right
        } else if (leftHip.y - rightHip.y > verticalThreshold) {
            return "upright"; // Straight posture
        } else {
            return "slouched"; // Default fallback
        }
    };


    const detectMicroexpressions = (detections) => {
        // Placeholder logic for microexpressions
        return "neutral";
    };

    // const detectGestures = async (poseDetector, video) => {
    //     // Placeholder logic for gestures
    //     return ["nodding"];
    // };


    const detectGestures = async (poseDetector, video) => {
        const poses = await poseDetector.estimatePoses(video);

        if (!poses || poses.length === 0) {
            return ["no gesture detected"]; // Return if no pose is detected
        }

        const pose = poses[0]; // Assume a single person for simplicity
        const keypoints = pose.keypoints;

        if (!keypoints) {
            return ["incomplete pose data"];
        }

        // Extract specific keypoints for gesture detection
        const nose = keypoints.find((point) => point.name === "nose");
        const leftShoulder = keypoints.find((point) => point.name === "left_shoulder");
        const rightShoulder = keypoints.find((point) => point.name === "right_shoulder");
        const leftElbow = keypoints.find((point) => point.name === "left_elbow");
        const rightElbow = keypoints.find((point) => point.name === "right_elbow");

        if (!nose || !leftShoulder || !rightShoulder || !leftElbow || !rightElbow) {
            return ["incomplete keypoints"];
        }

        // Placeholder variables to track gestures
        let gestures = [];

        // Detect nodding (vertical movement of the nose)
        if (nose.previousY && Math.abs(nose.y - nose.previousY) > 15) {
            gestures.push("nodding");
        }

        // Detect shaking head (horizontal movement of the nose)
        if (nose.previousX && Math.abs(nose.x - nose.previousX) > 20) {
            gestures.push("shaking head");
        }

        // Detect waving (high movement of arms)
        const leftWrist = keypoints.find((point) => point.name === "left_wrist");
        const rightWrist = keypoints.find((point) => point.name === "right_wrist");

        if (leftWrist && leftShoulder && leftWrist.y < leftShoulder.y) {
            gestures.push("waving left hand");
        }

        if (rightWrist && rightShoulder && rightWrist.y < rightShoulder.y) {
            gestures.push("waving right hand");
        }

        // Detect crossing arms (proximity of wrists to shoulders)
        if (
            leftWrist &&
            rightWrist &&
            Math.abs(leftWrist.x - rightWrist.x) < 50 &&
            Math.abs(leftWrist.y - rightWrist.y) < 20
        ) {
            gestures.push("crossing arms");
        }

        // Track previous positions for temporal gesture detection
        keypoints.forEach((point) => {
            point.previousX = point.x;
            point.previousY = point.y;
        });

        return gestures.length > 0 ? gestures : ["no gesture detected"];
    };


    const calculateEngagement = (emotions, gestures) => {
        return emotions.length * 10 + gestures.length * 5;
    };


    // const calculateEngagement = (emotions, gestures) => {
    //     // Weights for emotions based on assumed engagement impact
    //     const emotionWeights = {
    //       happy: 10,
    //       surprised: 8,
    //       neutral: 5,
    //       sad: -5,
    //       angry: -10,
    //       fearful: -8,
    //       disgusted: -6,
    //     };

    //     // Weights for gestures based on assumed engagement impact
    //     const gestureWeights = {
    //       nodding: 10,
    //       shakingHead: -5,
    //       waving: 8,
    //       crossingArms: -8,
    //       leaningForward: 12,
    //       leaningBack: -12,
    //     };

    //     // Calculate emotion engagement
    //     let emotionScore = 0;
    //     for (const emotion of emotions) {
    //       if (emotionWeights[emotion]) {
    //         emotionScore += emotionWeights[emotion];
    //       }
    //     }

    //     // Calculate gesture engagement
    //     let gestureScore = 0;
    //     for (const gesture of gestures) {
    //       if (gestureWeights[gesture]) {
    //         gestureScore += gestureWeights[gesture];
    //       }
    //     }

    //     // Final engagement score
    //     const engagementScore = emotionScore + gestureScore;

    //     // Return a normalized engagement score
    //     return Math.max(0, Math.min(engagementScore, 100)); // Ensure the score stays between 0 and 100
    //   };




    async function mergeAudioStreams(ttsAudioBlob, audioTracks) {
        // Step 1: Create an AudioContext to handle the decoding and format conversion
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        // Step 2: Decode the TTS audio blob into audio buffer
        const ttsArrayBuffer = await ttsAudioBlob.arrayBuffer();
        const ttsAudioBuffer = await audioContext.decodeAudioData(ttsArrayBuffer);

        // Step 3: Get the sample rate, number of channels, and duration of the TTS audio
        const ttsSampleRate = ttsAudioBuffer.sampleRate;
        const ttsNumberOfChannels = ttsAudioBuffer.numberOfChannels;

        // Step 4: Get the format of the webcam audio (audioTracks)
        const webcamAudioContext = new (window.AudioContext || window.webkitAudioContext)();
        const webcamSource = webcamAudioContext.createMediaStreamSource(new MediaStream(audioTracks));

        const webcamSampleRate = webcamAudioContext.sampleRate;
        const webcamNumberOfChannels = webcamSource.channelCount;

        // Step 5: Check compatibility
        let resampledTTSBuffer;
        if (ttsSampleRate !== webcamSampleRate || ttsNumberOfChannels !== webcamNumberOfChannels) {
            // If the formats don't match, resample the TTS audio to match the webcam audio format
            const offlineContext = new OfflineAudioContext(
                webcamNumberOfChannels,
                ttsAudioBuffer.duration * webcamSampleRate,
                webcamSampleRate
            );

            // Create buffer source for TTS audio and connect to offline context
            const ttsSource = offlineContext.createBufferSource();
            ttsSource.buffer = ttsAudioBuffer;
            ttsSource.connect(offlineContext.destination);
            ttsSource.start();

            // Render the resampled buffer
            resampledTTSBuffer = await offlineContext.startRendering();
        } else {
            resampledTTSBuffer = ttsAudioBuffer; // No resampling needed
        }

        // Step 6: Create a destination for merging both tracks
        const destination = audioContext.createMediaStreamDestination();

        // Step 7: Connect both webcam audio and TTS audio to the destination
        const ttsSourceNode = audioContext.createBufferSource();
        ttsSourceNode.buffer = resampledTTSBuffer;
        ttsSourceNode.connect(destination);

        const webcamSourceNode = audioContext.createMediaStreamSource(new MediaStream(audioTracks));
        webcamSourceNode.connect(destination);

        // Play the TTS audio
        ttsSourceNode.start();

        // Step 8: Return the merged audio stream
        return destination.stream; // This contains both TTS and webcam audio merged
    }



    async function streamImageInCornerAndMerge(imageSrc, canvasStream) {
        try {
            // Step 1: Create a canvas element and get its context
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            // Step 2: Set canvas dimensions to match the webcam video stream's dimensions
            const videoTrack = canvasStream.getVideoTracks()[0];
            const videoSettings = videoTrack.getSettings();
            canvas.width = videoSettings.width;
            canvas.height = videoSettings.height;

            // Step 3: Create a video element to play the webcam stream
            const videoElement = document.createElement('video');
            videoElement.srcObject = canvasStream;
            await videoElement.play();

            // Step 4: Load the image to overlay
            const image = new Image();
            image.src = imageSrc;

            await new Promise((resolve, reject) => {
                image.onload = resolve;
                image.onerror = reject;
            });

            // Step 5: Dynamically scale the image to fit within the canvas
            const maxImageWidth = canvas.width * 0.3; // 30% of canvas width
            const maxImageHeight = canvas.height * 0.3; // 30% of canvas height
            let imageWidth = image.width;
            let imageHeight = image.height;

            if (imageWidth > maxImageWidth) {
                const scalingFactor = maxImageWidth / imageWidth;
                imageWidth = maxImageWidth;
                imageHeight *= scalingFactor;
            }

            if (imageHeight > maxImageHeight) {
                const scalingFactor = maxImageHeight / imageHeight;
                imageHeight = maxImageHeight;
                imageWidth *= scalingFactor;
            }

            // Step 6: Position the image in the corner
            const imageX = 20; // 20px from the left
            const imageY = 20; // 20px from the top

            // Step 7: Animation loop to draw the video and the image onto the canvas
            const updateCanvas = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // Draw the webcam video onto the canvas
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                // Draw the scaled image onto the canvas
                ctx.drawImage(image, imageX, imageY, imageWidth, imageHeight);

                // Continue the animation loop
                requestAnimationFrame(updateCanvas);
            };

            updateCanvas();

            // Step 8: Capture the canvas stream (which now contains the webcam video + image)
            const imageStream = canvas.captureStream(30); // Capture at 30fps

            // Step 9: Return the combined video stream
            return imageStream;

        } catch (error) {
            console.error('Error while combining image and video stream:', error);
            throw error;
        }
    }



    const handleOnComplete = async (fullConversationText) => {
        setIsListening(false);
        setIsSpeaking(false);
        setCountdownActive(false); // Stop the countdown when recognition completes
        setIsWaiting(true);

        if (recorderRef.current) {

            if (recorderRef.current.getState() === 'recording') {
                recorderRef.current.stopRecording(async () => {
                    const blob = recorderRef.current.getBlob();

                    const uniqueId = uuidv4();  // Generate a unique identifier
                    const filename = `videos/question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.mp4`;
                    // const filename = `question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.webm`;
                    const azurefilename = `videos/question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.webm`;

                    // Save the initial response data and get the responsedataid
                    const initialResponseData = {
                        "questionnumber": CurrentQuestionNumber,
                        "candidate": candidateId,
                        "interview": interviewId,
                        "questiontext": currentquestionstr.current,
                        "response_text": fullConversationText,
                        "response_time": responseStartTm.current,
                        "questionstarttime": questionStartTm.current,
                        "responseendtime": responseStartTm.current, // the variable is just passed for placeholder. actual responded time is filled at server side
                        "usercompany": userCompany,
                        "behavior": currentAnalysis.current
                    };

                    let responsedataid;
                    try {
                        const response = await axios.post(`${config.apiBaseUrl}/api/record_response/`, initialResponseData);
                        responsedataid = response.data.response_id;
                        //  console.log("Initial response saved successfully with ID:", responsedataid);
                    } catch (error) {
                        console.error("Error saving initial response:", error);
                        return;
                    }

                    const isCloudflare = true; // Set this flag as needed (True for Cloudflare, False for Azure)
                    // Merge the TTS audio stream with the recorded video blob before uploading
                    if (isCloudflare) {
                        uploadVideoToCloudflare(blob, responsedataid, filename)
                            .then(cloudflareUrl => {
                                // console.log("Upload completed");
                            })
                            .catch((error) => {
                                console.error("Error uploading video", error);
                            });
                    } else {
                        // Upload to Azure Blob Storage
                        uploadVideoToAzure(blob, azurefilename, responsedataid)
                            .then(azureUrl => {
                                console.log("Upload completed and Azure URL obtained:");
                            })
                            .catch((error) => {
                                console.error("Error uploading video to Azure:", error);
                            });
                    }

                    // Start next question immediately without waiting for the upload to complete
                    setIsWaiting(false);

                    if (CurrentQuestionNumber < totalQuestions) {
                        setCurrentQuestionNumber(CurrentQuestionNumber + 1);
                    } else {
                        endInterviewandNavigate();
                    }
                    // setCurrentQuestionNumber(CurrentQuestionNumber + 1);
                });
            }
        }
    };



    function downloadBlob(blob, filename) {
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        // Create an anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;  // Set the file name

        // Append the anchor to the body
        document.body.appendChild(a);

        // Programmatically click the anchor to trigger the download
        a.click();

        // Clean up by removing the anchor and revoking the object URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }



    // this function gets a safe upload url from backend and then uploads the video to cloudflare    
    async function uploadVideoToCloudflare(blob, responsedataid, filename) {
        try {
            // Step 1: Request a short-lived, time-limited upload URL from the backend

            const response = await axios.post(`${config.apiBaseUrl}/api/get-cloudflare-upload-url/`, {
                interviewIdurl: interviewIdurl
            });


            // Step 2: Use the provided URL to upload the video file directly to Cloudflare
            const uploadURL = response.data.upload_url;
            const videoid = response.data.video_id;

            const formData = new FormData();

            formData.append("file", blob, filename);  // Pass the filename as the third argument

            const uploadResponse = await axios.post(uploadURL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            const updateResponseData = {
                "responsedataid": responsedataid,
                "video_url": videoid,
                "cloudflare": 1,
                "cloudflare_filename": filename
            };
            await axios.post(`${config.apiBaseUrl}/api/save_video_filename/`, updateResponseData);
            // combinedStream.getTracks().forEach(track => track.stop());
            // combinedStream = null;

            // downloadBlob(blob, filename);
            return videoid;

        } catch (error) {
            console.error("Error uploading video:", error);
            throw error;
        }
    }



    function uploadVideoToAzure(blob, filename, responsedataid) {
        return axios.get(`${config.apiBaseUrl}/api/get_sas_token/`, { params: { blob_name: filename } })
            .then(response => {
                const sasToken = response.data.sas_token;
                const azureUrl = `https://aiinterviewstorage.blob.core.windows.net/aiblobstorage/${filename}?${sasToken}`;
                //console.log("sasToken *******", sasToken)

                const config1 = {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        // 'Content-Type': blob.type,
                        // 'Content-Type':  "video/mp4",
                        'Content-Type': "video/webm",

                    }
                };

                return axios.put(azureUrl, blob, config1)
                    .then(() => {
                        // Save the URL in the database after the upload completes
                        //console.log("azureUrl *******", azureUrl)

                        const updateResponseData = {
                            "responsedataid": responsedataid,
                            "video_url": azureUrl,
                            "cloudflare": 0,
                        };
                        return axios.post(`${config.apiBaseUrl}/api/save_video_filename/`, updateResponseData)
                            .then(() => azureUrl)
                            .catch(error => {
                                console.error("Error updating video URL:", error);
                                throw error;
                            });
                    })
                    .catch(error => {
                        console.error("Error during file upload:", error);
                        throw error;
                    });
            })
            .catch(error => {
                console.error("Error getting SAS token:", error);
                throw error;
            });
    }





    // this function inserts parameters into instructions // DO NOT DELETE. WILL NEED in future
    // const formatTemplate = (template, values, wrapper = '') => {
    //     return template.replace(/\${(.*?)}/g, (match, p1) => {
    //         return values[p1] ? `${values[p1]}` : match;
    //     });
    // };


    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return 'Time Eapsed: ' && `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };


    const handleEndInterview = async () => {
        const confirmEnd = window.confirm('Do you want to end the interview?');
        if (confirmEnd) {

            try {
                const response = await axios.post(`${config.apiBaseUrl}/api/end_interview/${interviewId}/`);
                // console.log(response.data.status);
                combinedStream.getTracks().forEach(track => track.stop());
                combinedStream = null;

                navigate('/thank-you'); // Redirect to thank you page
            } catch (error) {
                console.error("Error ending interview:", error);
            }
        }
    };


    const handleCheckboxChange = () => {
        setShowGuide(!showGuide); // Toggle the state of showGuide
    };

    const callChatAPI = async (userInput) => {

        // console.log(userInput);


        const responseData = {
            instruction: userInput   // instruction is the variable chat api will look for. if instruction variable is not present chatapi will give respone 500
        };


        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/chat/`, responseData);

            // console.log('Chat API response:', response.data);
            return response.data.question;
        } catch (error) {
            console.error('Error calling chat API:', error);
            return null;
        }
    };

    const fetchQuestionInstruction = async (interviewId) => {
        try {

            const response = await axios.get(`${config.apiBaseUrl}/api/question_instruction/${interviewId}/`);
            return response.data;
        } catch (error) {
            console.error('Error fetching question instruction:', error);
            return null;
        }
    };



    useEffect(() => {

        let interval;
        if (interviewStarted && !interviewEnded) {
            interval = setInterval(() => {
                setTimeElapsed(prevTime => {
                    if (prevTime + 1 >= 5400) { // 5400 seconds = 90 minutes
                        // Action to take when 90 minutes have elapsed
                        console.log('90 minutes have elapsed');
                        combinedStream.getTracks().forEach(track => track.stop());
                        combinedStream = null;
                        navigate('/thank-you'); // Redirect to thank you page
                    }
                    return prevTime + 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [interviewStarted, interviewEnded]);

    const getVideoConstraints = () => {
        if (isIphone) {
            const isPortrait = window.innerHeight > window.innerWidth; // Check orientation
            return isPortrait
                ? { width: 360, height: 640, aspectRatio: 3 / 3 } // Portrait constraints (3:4)
                : { width: 640, height: 360, aspectRatio: 9 / 9 }; // Landscape constraints (16:9)
        }
        // return { width: 640, height: 360 }; // Default constraints for other devices
        return { width: 640, height: 360 }; // Default constraints for other devices
    };

    // // Adjust CSS dynamically for iPhone
    // const webcamStyles = isIphone
    //     ? {
    //         position: 'absolute',
    //         top: 1,
    //         left: 1,
    //         width: '100%', // Stretch to fit container
    //         height: '100%', // Stretch to fit container
    //         objectFit: 'contain', // Avoid stretching
    //         transform: window.innerHeight > window.innerWidth ? 'scale(1)' : 'scale(0.5)', // Landscape fix
    //     }
    //     : {
    //         position: 'absolute',
    //         top: 1,
    //         left: 1,
    //         width: '320px',
    //         height: '180px',
    //         objectFit: 'cover', // Default behavior for non-iPhone
    //     };


    // const setCountdown = (isActive) => {
    //     console.log('setCountdown triggerd');
    //     // setCountdownActive(isActive);

    //     if (isActive) {
    //         setIsListening(false); 

    //         if (!toastId) {

    //             const id = toast.error("Silence detected! Next question in 3 seconds", {
    //                 position: "top-right",
    //                 autoClose: 4000, // Toast automatically closes after 4 seconds
    //                 hideProgressBar: false,
    //                 closeOnClick: false,
    //                 pauseOnHover: false,
    //                 draggable: false,
    //                 progress: undefined,
    //                 theme: "colored", // Can be "light", "dark", or "colored"
    //                 });
    //             setToastId(id);
    //     } else {
    //         toast.update(toastId, {
    //           render: "Silence detected! Next question in 3 seconds",
    //           type: toast.TYPE.ERROR,
    //         });
    //     }  

    //     if (!isActive) {
    //         // Additional actions when countdown is not active
    //         setIsListening(true); // if we get setcountDown as false that means candidate spoke after countdown was shown. so we show the recording icon again
    //         if (toastId) {
    //             toast.dismiss(toastId);
    //           }
    //     }
    // };

    const setCountdown = (isActive) => {
    
        if (isActive) {
            setIsListening(false);
            // console.log('Countdown triggered');
    
            // Show the toast if not already displayed
            const iddd = toast.error("Silence detected! Next question in 3 seconds", {
                position: "top-right",
                autoClose: 4000, // Toast automatically closes after 4 seconds
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored", // Can be "light", "dark", or "colored"
                closeButton: false, // Hides the close button
                style: {
                    backgroundColor: "#dc3545", // Customize background color (red in this case)
                    color: "white", // Customize text color (white)
                },
                onClose: () => {
                    // Reset toastId to null when the toast is closed
                    toastIdRef.current = null;
                },

            });
            toastIdRef.current = iddd; // Instantly store toastId in ref
            // console.log("Created toast ID:", toastIdRef.current);

        } else {
            // Additional actions when countdown is not active
            // console.log('Countdown disabling toast', toastIdRef.current);
            setIsListening(true); // Candidate spoke after countdown, so show recording icon again
            if (toastIdRef.current) {
                // Dismiss the toast if it exists
                toast.dismiss(toastIdRef.current);
                toastIdRef.current = null; // Reset toastId in the ref

            }
        }
    };

    return (
        <div className="interview-screen" style={{ backgroundColor: avatarbackgroundColor }}>
            <img src={avatarimageOption} alt="Avatar" className="avatar" />
            <Rnd
                default={{
                    x: 1,
                    y: 1,
                    width: 320,
                    height: 180,
                }}
                bounds="parent"
            >
                <div className="webcam-container" style={{
                    position: 'relative',
                    width: '320px',
                    height: '180px'
                }}>
                    <Webcam
                        audio={true} // Enable audio input for recording
                        muted={true} // Mute for non-Safari browsers
                        ref={webcamRef}
                        videoConstraints={getVideoConstraints()} // Dynamic constraints for iPhone
                        className="webcam"
                        onUserMedia={() => console.log('Webcam loaded')}
                        onUserMediaError={(err) => console.error('Webcam error:', err)}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%', // Adjust to fill the container
                        }}
                    />
                    {showGuide && !interviewStarted && (
                        <img
                            src="/face-guide.webp" // Replace this path with your actual image path
                            alt="Face Guide"
                            style={{
                                position: 'absolute',
                                top: '45%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '155px',
                                height: '155px', // Set dimensions as needed
                                zIndex: 10 // Ensure this is higher than the webcam's z-index
                            }}
                        />
                    )}
                    <canvas
                        ref={canvasRef}
                        width={320} // Adjust for portrait and landscape modes
                        height={180} // Adjust for portrait and landscape modes
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%', // Adjust to fill the container
                        }}
                    />
                </div>
            </Rnd>
            {!interviewStarted && (

                <button
                    onClick={handleStartInterview}
                    className="start-interview-button"
                    disabled={interviewStarted || isWebcamProblem}
                >
                    Start Interview
                </button>
            )}
            <div
                className="question-container"
            >
                {interviewStarted ? (
                    <p>{currentquestionstr.current}</p>
                ) : (
                    <p>{currentTextstr.current}</p>
                )}
            </div>
            {isSpeaking && (
                <div className="speaking-indicator">
                    <img src="/speaking-icon.webp" alt="Speaking" className="blinking-icon" />
                </div>
            )}
            {isListening && (
                <div className="listening-indicator">
                    <img src="/listening-icon.webp" alt="Listening" className="blinking-icon" />
                </div>
            )}
            {isWaiting && (
                <div className="waiting-indicator">
                    <img src="/waiting-icon.webp" alt="Waiting" className="blinking-icon" />
                </div>
            )}
            {interviewStarted && !interviewEnded && (
                <button
                    onClick={handleEndInterview}
                    className="end-interview-button"
                    disabled={!interviewStarted} // Disable if interview hasn't started
                >
                    End Interview
                </button>
            )}


            {!interviewStarted && (
                <select
                    id="background-select"
                    value={backgroundOption}
                    className="background-select"
                    disabled={interviewStarted}
                    onChange={(e) => setBackgroundOption(e.target.value)}
                >
                    <option value="none">No Camera Background</option>
                    {!isSafari && <option value="blurred">Blurred</option>}
                    <option value="office1">Office 1</option>
                    <option value="office2">Office 2</option>
                    <option value="beach">Beach</option>
                </select>
            )}
            {!interviewStarted && (

                <label className="show-guide" title="Toggle this to see where to position your face on the webcam. The guide will disappear when you will click Start Interview button">
                    Enable Face Positioning Guide{"  "}
                    <input
                        type="checkbox"
                        checked={showGuide}
                        onChange={handleCheckboxChange}
                        disabled={interviewStarted}
                        title="Toggle this to see where to position your face on the webcam. The guide will disappear when you will click Start Interview button" // Tooltip on the checkbox as well
                    />
                </label>
            )}


            <ToastContainer
                style={{ zIndex: 9999 }}
            />

            {!interviewStarted && (

                <div
                    style={{
                        position: 'absolute',
                        bottom: '280px',
                        right: '30px',
                        fontSize: '12px',
                        color: 'white',
                        width: '300px',
                        textAlign: 'right'
                    }}
                >
                    By clicking 'Start Interview' button, you agree to our
                    <a
                        href="https://www.bitsoft.com/privacy-policy-and-candidate-agreement/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'white', textDecoration: 'underline', marginLeft: '5px' }}
                    >
                        Terms and Conditions
                    </a>
                </div>
            )}

            <div
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    fontSize: '18px',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional: adds a semi-transparent background for better readability
                    padding: '5px', // optional: adds padding around the text
                    borderRadius: '5px', // optional: adds rounded corners
                }}
            >
                Time elapsed: {formatTime(timeElapsed)}
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '12px',
                    color: 'white',
                    padding: '5px',
                }}
            >
                <a
                    href="https://www.bitsoft.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'white', textDecoration: 'none' }}
                >
                    Bitsoft International, Inc © 2025
                </a>
            </div>
        </div>
    );
};

export default InterviewScreen;




// import React, { useState, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import { Rnd } from 'react-rnd';
// import axios from 'axios';
// import RecordRTC from 'recordrtc';
// import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';


// import './InterviewScreen.css';
// import { initializeSpeechRecognizer, startContinuousRecognition } from '../utils/longSpeechToText';
// import { useNavigate } from 'react-router-dom';
// import config from '../config';
// import { v4 as uuidv4 } from 'uuid';
// import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
// import * as tf from '@tensorflow/tfjs';
// // eslint-disable-next-line
// import * as faceapi from '@vladmandic/face-api';
// import * as poseDetection from '@tensorflow-models/pose-detection';
// import CountdownTimer from '../utils/CountdownTimer';


// let combinedStream = null;
// let mergedAudioStream = null;
// let audioTracks = null;

// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// const isIphone = /iPhone/i.test(navigator.userAgent);



// const InterviewScreen = ({ interviewId, candidateId, interviewIdurl }) => {
//     const [isSpeaking, setIsSpeaking] = useState(false);
//     const [isWaiting, setIsWaiting] = useState(false);

//     const [isListening, setIsListening] = useState(false);
//     const [interviewStarted, setInterviewStarted] = useState(false);
//     const [interviewEnded, setInterviewEnded] = useState(false);
//     const webcamRef = useRef(null);
//     const canvasRef = useRef(null);

//     const synthesizerRef = useRef(null);
//     const recognizerRef = useRef(null);
//     const recorderRef = useRef(null);
//     const [CurrentQuestionNumber, setCurrentQuestionNumber] = useState(0);
//     const [timeElapsed, setTimeElapsed] = useState(0);
//     const navigate = useNavigate();
//     const currentquestionstr = useRef("");
//     const questionStartTm = useRef(new Date());
//     const responseStartTm = useRef(new Date());
//     const currentTextstr = useRef("");
//     const segmentationRef = useRef(null);
//     const [backgroundOption, setBackgroundOption] = useState('none');
//     const [avatarbackgroundColor, setAvatarbackgroundColor] = useState('white');
//     const [languageOption, setLanguageOption] = useState('none');
//     const [avatarimageOption, setAvatarimageOption] = useState('Avatar.png');
//     const [isWebcamProblem, setIsWebcamProblem] = useState(false); // Track support for getUserMedia Webcam
//     const [userCompany, setUserCompany] = useState(0);

//     currentTextstr.current = "When you're ready, click 'Start Interview'. The AI Interviewer will ask a series of questions and wait for your response, indicated by a flashing RED icon. Please answer each question as thoroughly as possible. After 5 SECONDS of silence, the interviewer will automatically proceed to the next question. You may end the interview at any time by clicking 'End Interview'."
//     const subscriptionKey = '49cfae0357764d2b86ff759b7f2eeeb9'; // this is for AIInterview2
//     const serviceRegion = 'centralus';
//     // const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
//     // let speechConfig;

//     const [modelsReady, setModelsReady] = useState(false);
//     const [poseDetector, setPoseDetector] = useState(null);
//     const currentAnalysis = useRef("");
//     const [speechConfig, setspeechConfig] = useState(null);
//     const [showGuide, setShowGuide] = useState(false); // State to manage the guide visibility
//     const [countdownActive, setCountdownActive] = useState(false);



//     useEffect(() => {
//         const preloadModels = async () => {

//             try {
//                 // console.log("Initializing TensorFlow.js backend...");
//                 await tf.setBackend("webgl");
//                 await tf.ready();
//                 // console.log(`TensorFlow.js backend initialized: ${tf.getBackend()}`);

//                 // Load FaceAPI models
//                 const MODEL_URL = "https://cdn.jsdelivr.net/gh/vladmandic/face-api/model/";
//                 // console.log("Loading FaceAPI models...");
//                 await Promise.all([
//                     faceapi.nets.tinyFaceDetector.loadFromUri(`${MODEL_URL}tiny_face_detector_model-weights_manifest.json`),
//                     faceapi.nets.faceExpressionNet.loadFromUri(`${MODEL_URL}face_expression_model-weights_manifest.json`),
//                     faceapi.nets.faceLandmark68Net.loadFromUri(`${MODEL_URL}face_landmark_68_model-weights_manifest.json`),
//                 ]);
//                 // console.log("FaceAPI models loaded.");

//                 // Initialize Pose Detector
//                 // console.log("Initializing Pose Detector...");
//                 const detector = await poseDetection.createDetector(poseDetection.SupportedModels.MoveNet);
//                 setPoseDetector(detector); // Store in state
//                 // console.log("Pose detector initialized.");

//                 setModelsReady(true); // Indicate that all models are ready
//             } catch (error) {
//                 console.error("Error preloading models:", error);
//             }
//         };

//         preloadModels();
//     }, []);


//     useEffect(() => {
//         const checkWebcam = async () => {
//             const isSupported = await checkWebcamSupport();
//             if (!isSupported) {
//                 // console.log("Webcam not supported or accessible.");
//                 setIsWebcamProblem(true); // true means webcam is a problem. should disable start interview button
//             } else {
//                 // console.log("Webcam is supported and accessible.");
//                 setIsWebcamProblem(false);

//             }
//         };
//         checkWebcam();
//     }, []);


//     const checkWebcamSupport = async () => {
//         // Check if getUserMedia is supported
//         if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
//             alert("Webcam is not supported on your browser. Please update your browser or use a modern one like Chrome or Safari.");
//             return false;
//         }

//         try {
//             // Attempt to access the webcam
//             const stream = await navigator.mediaDevices.getUserMedia({ video: true });

//             // Stop the video stream immediately after checking
//             stream.getTracks().forEach((track) => track.stop());

//             return true; // Webcam is supported and accessible
//         } catch (error) {
//             if (error.name === 'NotAllowedError') {
//                 alert("Access to the webcam is blocked. Please allow webcam access in your browser settings.");
//             } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
//                 alert("No webcam detected. Please ensure your webcam is connected.");
//             } else {
//                 alert("Unable to access the webcam. Please try again or update your browser.");
//             }
//             return false;
//         }
//     };


//     // Initialize Speech Synthesizer
//     const initializeSpeechSynthesizer = (voicename) => {

//         try {
//             speechConfig.speechSynthesisVoiceName = voicename || "en-US-AndrewMultilingualNeural"; // Default voice if none passed

//             if (isIphone) {
//                 // For iPhone, use PullAudioOutputStream
//                 const audioStream = SpeechSDK.AudioOutputStream.createPullStream();
//                 const audioConfig = SpeechSDK.AudioConfig.fromStreamOutput(audioStream);
//                 const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
//                 console.log("Speech Synthesizer initiated for iPhone")
//                 return { synthesizer, audioStream }; // Return audioStream for manual playback
//             } else {
//                 // Default behavior for non-iPhone
//                 const player = new SpeechSDK.SpeakerAudioDestination();
//                 const audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
//                 const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
//                 console.log("Speech Synthesizer initiated for non-iPhone")
//                 return { synthesizer, player };
//             }
//         } catch (error) {
//             console.error("Failed to initialize speech synthesizer:", error);
//             return null;
//         }
//     };

//     // Function to Speak Text
//     const speakText = (synthesizerObj, text, onComplete) => {
//         if (!synthesizerObj) {
//             console.error("Invalid synthesizer object.");
//             if (onComplete) onComplete();
//             return;
//         }

//         const { synthesizer, player, audioStream } = synthesizerObj;

//         const playAudioFromStream = async (audioArrayBuffer, onComplete) => {
//             try {
//                 const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//                 const audioBuffer = await audioContext.decodeAudioData(audioArrayBuffer);
//                 const source = audioContext.createBufferSource();
//                 source.buffer = audioBuffer;
//                 source.connect(audioContext.destination);

//                 // Start playback
//                 source.start();

//                 // Invoke `onComplete` when playback ends
//                 source.onended = () => {
//                     if (onComplete) onComplete();
//                 };
//             } catch (error) {
//                 console.error("Web Audio API playback failed:", error);
//                 if (onComplete) onComplete(); // Ensure onComplete is called even in case of errors
//             }
//         };

//         try {
//             synthesizer.speakTextAsync(
//                 text,
//                 async (result) => {
//                     if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
//                         const audioData = result.audioData; // Raw audio data



//                         const ttsAudioBlob = isSafari
//                             ? (isIphone
//                                 ? new Blob([result.audioData], { type: "audio/aac" }) // iPhone-specific format
//                                 : new Blob([result.audioData], { type: "audio/mpeg" })) // Safari-compatible format
//                             : new Blob([result.audioData], { type: "WebM" }); // Default format

//                         setTTSBlob(ttsAudioBlob);

//                         if (isIphone) {
//                             // Use Web Audio API for playback on iPhones
//                             console.log("Using Web Audio API for iPhone.");
//                             const audioArrayBuffer = new Uint8Array(result.audioData).buffer;

//                             playAudioFromStream(audioArrayBuffer, onComplete); // Pass `onComplete` to be called after playback
//                         } else {
//                             // Non-Safari browsers use player directly
//                             player.onAudioEnd = () => {
//                                 if (onComplete) onComplete();
//                             };
//                         }

//                     } else {
//                         console.error("Speech synthesis error:", result.errorDetails);
//                         if (onComplete) onComplete();
//                     }
//                     synthesizer.close(); // Close the synthesizer
//                 },
//                 (err) => {
//                     console.error("Speech synthesis failure:", err);
//                     if (onComplete) onComplete();
//                     synthesizer.close();
//                 }
//             );
//         } catch (error) {
//             console.error("Error during speech synthesis:", error);
//             if (onComplete) onComplete();
//         }
//     };




//     const backgrounds = [
//         'white',
//         'navy',
//         'slategray',
//         'darkslateblue',
//         'midnightblue',
//         'teal',
//         'olive',
//         'beige',
//         'lavender',
//         'coral',
//         'firebrick',
//         'darkgreen',
//         'steelblue',
//         'saddlebrown',
//         'darkolivegreen',
//         'dimgray',
//         'lightslategray',
//         'indigo',
//         'mediumorchid',
//         'rebeccapurple',
//         'slateblue'
//     ];

//     const backgroundImageRefs = {
//         webcambackground: useRef(new Image())
//     };

//     useEffect(() => {
//         const updateBackgroundImage = () => {
//             if (backgroundOption !== 'none' && backgroundOption !== 'blurred') {
//                 backgroundImageRefs.webcambackground.current.src = `${backgroundOption}.webp`;
//                 backgroundImageRefs.webcambackground.current.onload = () => {
//                     // console.log(`${backgroundOption} background image successfully loaded`);
//                 };
//                 backgroundImageRefs.webcambackground.current.onerror = () => {
//                     console.error(`Failed to load the background image: ${backgroundOption}`);
//                 };
//             } else {
//                 backgroundImageRefs.webcambackground.current.src = ''; // Clear the source if 'none' or 'blurred'
//             }
//         };

//         updateBackgroundImage();
//     }, [backgroundOption]);




//     const segmentation = new SelfieSegmentation({ locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}` });
//     segmentation.setOptions({
//         modelSelection: 1,
//     });


//     const handleStartInterview = async () => {

//         if (isSafari && !isIphone) {
//             alert(
//                 "Click Close to start the interview.\n\n"
//             );

//         }

//         setspeechConfig(SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion));



//         setInterviewStarted(true);
//         try {
//             currentquestionstr.current = "Ready for your interview? Please wait..."
//             const response = await axios.get(`${config.apiBaseUrl}/api/initial_data/${interviewId}/`);
//             setUserCompany(response.data.usercompany)
//             setCurrentQuestionNumber(CurrentQuestionNumber + 1);
//         } catch (error) {
//             console.error("Error fetching initial data:", error);
//         }
//     };

//     useEffect(() => {

//         const loadSegmentation = async () => {
//             segmentation.onResults(onResults);
//             segmentationRef.current = segmentation;
//             if (webcamRef.current && webcamRef.current.video.readyState === 4) {
//                 segmentVideo();
//             } else {
//                 webcamRef.current.video.onloadeddata = () => {
//                     segmentVideo();
//                 };
//             }
//         };
//         loadSegmentation();


//         return () => {
//             if (webcamRef.current && webcamRef.current.video) {
//                 webcamRef.current.video.onloadeddata = null;
//             }
//         };

//     }, [backgroundOption]);

//     const segmentVideo = async () => {
//         if (!webcamRef.current || !segmentationRef.current) {
//             return;
//         }
//         const { video } = webcamRef.current;
//         segmentationRef.current = segmentation;
//         await segmentationRef.current.send({ image: video }); // this line gives error on changing if one video effect is running 


//         requestAnimationFrame(segmentVideo);
//     };

//     const onResults = async (results) => {
//         if (!canvasRef.current || !webcamRef.current) {
//             return;
//         }
//         const canvas = canvasRef.current;
//         const context = canvas.getContext('2d');

//         if (canvas.width === 0) {
//             canvas.width = results.image.width;
//             canvas.height = results.image.height;

//         }


//         switch (backgroundOption) {
//             case 'none':
//                 context.save();
//                 context.clearRect(0, 0, canvas.width, canvas.height);
//                 context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//                 break;

//             case 'blurred':
//                 context.save();
//                 context.clearRect(0, 0, canvas.width, canvas.height);
//                 context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
//                 context.globalCompositeOperation = 'source-in';
//                 context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//                 context.globalCompositeOperation = 'destination-atop';
//                 context.filter = 'blur(6px)';
//                 context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//                 //context.restore();
//                 break;

//             case 'office1':
//             case 'office2':
//             case 'beach':
//                 // console.log("office1 hello");
//                 // Draw the background image
//                 // context.drawImage(backgroundImageRefs.office1.current, 0, 0, canvas.width, canvas.height);
//                 context.drawImage(backgroundImageRefs.webcambackground.current, 0, 0, canvas.width, canvas.height);
                
//                 // Draw the segmentation mask
//                 context.globalCompositeOperation = 'destination-out';
//                 context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);

//                 // Draw the original image on top to keep the person clear
//                 context.globalCompositeOperation = 'destination-atop';
//                 context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//                 // Reset the global composite operation
//                 context.globalCompositeOperation = 'source-over';
//                 break;

//             default:
//                 context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//                 break;
//         }

//         context.restore();
//     };


//     const startInterview = async () => {

//         if (interviewStarted) {
//             setIsWaiting(true);
//             console.log("languageOption", languageOption)
//             synthesizerRef.current = initializeSpeechSynthesizer(languageOption);
//             recognizerRef.current = initializeSpeechRecognizer();
//             const questionData = await fetchQuestionInstruction(interviewId);
//             currentquestionstr.current = questionData.aiuser_instruction;
//             currentTextstr.current = questionData.aiuser_instruction;
//             questionStartTm.current = questionData.current_time
//             responseStartTm.current = questionStartTm.current
//             setIsWaiting(false);

//             if (questionData.action_id === 1)   // 1 means its just a text to be spoken
//             {
//                 await processQuestion(questionData.aiuser_instruction, questionData.action_id, handleOnComplete);
//             }

//             if (questionData.action_id === 2)   // 2 means its a question and will listen for response
//             {
//                 await processQuestion(questionData.aiuser_instruction, questionData.action_id, handleOnComplete);
//             }

//             if (questionData.action_id === 3)   // 3 means the program will use last candidate response and get appropriate response from OpenAI
//             {
//                 const tempresponseText = await callChatAPI(questionData.aiuser_instruction);
//                 await processQuestion(tempresponseText, questionData.action_id, handleOnComplete);

//             }

//             if (questionData.action_id === 100) {
//                 setInterviewEnded(true)
//                 try {
//                     const response = await axios.post(`${config.apiBaseUrl}/api/end_completed/${interviewId}/`);
//                     combinedStream.getTracks().forEach(track => track.stop());
//                     combinedStream = null;

//                     navigate('/thank-you'); // Redirect to thank you page
//                 } catch (error) {
//                     console.error("Error ending interview:", error);
//                 }

//             }
//         }
//         else {
//         }

//     };


//     /* eslint-disable react-hooks/exhaustive-deps */
//     useEffect(() => {
//         const initiateInterview = async () => {
//             try {
//                 setIsSpeaking(false);
//                 setIsListening(false);
//                 setIsWaiting(false);



//                 if (!interviewStarted) {
//                     const response = await axios.get(`${config.apiBaseUrl}/api/get-avatar/${interviewId}/`);
//                     // Handle the response data
//                     const avatarData = response.data;
//                     setAvatarimageOption(avatarData.face_image)
//                     setAvatarbackgroundColor(backgrounds[avatarData.background])
//                     setLanguageOption(avatarData.voice)
//                 }

//                 await startInterview();
//             } catch (error) {
//                 console.error(error);
//             }
//         };
//         initiateInterview();
//     }, [CurrentQuestionNumber]);

//     const processQuestion = async (questionText, actionidd, handleOnComplete) => {
//         setIsWaiting(false);
//         setIsSpeaking(true);
//         // Start recording
//         if (canvasRef.current && webcamRef.current) {


//             if (actionidd === 1) { // 
//                 await speakText(synthesizerRef.current, questionText, () => {
//                     setIsSpeaking(false);

//                     if (handleOnComplete) {
//                         handleOnComplete("Response not expected");
//                     }
//                 }, setTTSBlob);
//             } else {
//                 await speakText(synthesizerRef.current, questionText, () => {
//                     setIsListening(true);
//                     setIsSpeaking(false);
//                     startContinuousRecognition(recognizerRef.current, handleOnComplete, setCountdown);
//                 }, setTTSBlob);
//             }

//         }
//     };


//     // async function inspectAudioBlob(audioBlob) {
//     //     // Print basic Blob properties
//     //     console.log('Blob size:', audioBlob.size, 'bytes');
//     //     console.log('Blob type:', audioBlob.type);

//     //     // Create an object URL to load the Blob as an audio element
//     //     const audioURL = URL.createObjectURL(audioBlob);

//     //     // Create an AudioContext (this should be defined outside the onloadedmetadata event)
//     //     const audioContext = new (window.AudioContext || window.webkitAudioContext)();

//     //     // Create an audio element to get basic audio metadata
//     //     const audio = new Audio();
//     //     audio.src = audioURL;

//     //     // Wait until the metadata is loaded
//     //     audio.onloadedmetadata = async () => {
//     //         console.log('Audio duration:', audio.duration, 'seconds');

//     //         try {
//     //             // Use the Web Audio API to decode the audio and get more detailed information
//     //             const arrayBuffer = await audioBlob.arrayBuffer();
//     //             const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

//     //             // Print detailed audio properties
//     //             console.log('Number of channels:', audioBuffer.numberOfChannels);
//     //             console.log('Sample rate:', audioBuffer.sampleRate, 'Hz');
//     //             console.log('Duration (from buffer):', audioBuffer.duration, 'seconds');

//     //             for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
//     //                 console.log(`Channel ${i + 1} data length:`, audioBuffer.getChannelData(i).length);
//     //             }
//     //         } catch (error) {
//     //             console.error('Error decoding audio data:', error);
//     //         }
//     //     };

//     //     // Handle error in loading the audio metadata
//     //     audio.onerror = (e) => {
//     //         console.error('Error loading audio metadata:', e);
//     //     };
//     // }


//     const setTTSBlob = async (audioBlob) => {
//         try {
//             // inspectAudioBlob(audioBlob);
//             console.log("Inside setTTSBlob");
//             let recorder = null;
//             const canvasStream = canvasRef.current.captureStream(30);
//             audioTracks = webcamRef.current.stream.getAudioTracks();

//             mergedAudioStream = await mergeAudioStreams(audioBlob, audioTracks);


//             if (!isIphone) {
//                 const srcAvatar = avatarimageOption;  // Path to your image file  
//                 const imageCombinedStream = await streamImageInCornerAndMerge(srcAvatar, canvasStream)
//                 combinedStream = new MediaStream([
//                     ...imageCombinedStream.getTracks(),
//                     ...mergedAudioStream.getAudioTracks()  // Extract audio tracks from mergedAudioStream
//                 ]);
//             }
//             else {
//                 combinedStream = new MediaStream([
//                     ...canvasStream.getTracks(),
//                     ...mergedAudioStream.getAudioTracks()  // Extract audio tracks from mergedAudioStream
//                 ]);

//                 // const srcAvatar = avatarimageOption;  // Path to your image file  
//                 // const imageCombinedStream = await streamImageInCornerAndMerge(srcAvatar, canvasStream)
//                 // combinedStream = new MediaStream([
//                 //     ...imageCombinedStream.getTracks(),
//                 //     ...mergedAudioStream.getAudioTracks()  // Extract audio tracks from mergedAudioStream
//                 // ]);
//             }

//             // combinedStream = new MediaStream([
//             //     ...canvasStream.getTracks(),
//             //     ...mergedAudioStream.getAudioTracks()  // Extract audio tracks from mergedAudioStream
//             // ]);

//             console.log("Before setting recording");


//             if (isIphone) {
//                 console.log("Recording in iphone");
//                 recorder = new RecordRTC(combinedStream, {
//                     type: 'video',
//                     mimeType: 'video/mp4', // MP4 for iPhone compatibility
//                     videoBitsPerSecond: 2500000, // 2.5 Mbps for good video quality
//                     audioBitsPerSecond: 128000,  // 128 kbps for high-quality audio
//                     disableLogs: true // Disable RecordRTC logs
//                 });
//             }
//             else {
//                 recorder = new RecordRTC(combinedStream, {
//                     type: 'video',
//                     mimeType: 'video/webm',
//                     videoBitsPerSecond: 2500000,  // 5 Mbps for best 720p video quality
//                     audioBitsPerSecond: 128000,   // 192 kbps for high-quality audio
//                     disableLogs: true // Disable RecordRTC logs
//                 });

//             }

//             recorder.startRecording();
//             recorderRef.current = recorder;


//             // Start real-time analysis during recording
//             if (!isIphone) {
//                 // startRealTimeAnalysis(combinedStream);
//             }

//         } catch (error) {
//             console.error("Error while setting TTS Blob:", error);
//         }
//     };



//     const startRealTimeAnalysis = async (stream) => {
//         try {
//             if (!modelsReady || !poseDetector) {
//                 console.warn("Models not ready yet.");
//                 return;
//             }
//             let analysisStarted = false; // Status variable to track if analysis has started

//             currentAnalysis.current = []; // Reset for a new session

//             // Setup video stream
//             // console.log("Current Time1:", new Date().toLocaleTimeString());

//             // console.log("Setting up video stream...");
//             const video = document.createElement("video");
//             video.srcObject = stream;
//             video.muted = true;

//             await video.play();



//             // Setup audio analysis
//             const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//             const analyser = audioContext.createAnalyser();
//             analyser.fftSize = 2048;
//             const audioDataArray = new Uint8Array(analyser.frequencyBinCount);

//             // Start the real-time analysis loop
//             console.log("Starting real-time analysis...");
//             let analysisLoop = setInterval(async () => {
//                 try {

//                     if (analysisStarted) { // Check exit conditions only after analysis has started
//                         if (!stream.active || stream.getTracks().some(track => track.readyState !== 'live')) {
//                             console.log("****************Stopping analysis loop due to stream end.************************");
//                             clearInterval(analysisLoop);
//                             video.pause();
//                             video.srcObject = null;
//                             stream.getTracks().forEach(track => track.stop());
//                             return;
//                         }
//                     }

//                     analysisStarted = true; // Set to true after the first loop iteration

//                     // **Visual Analysis**
//                     const detections = await faceapi
//                         .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
//                         .withFaceLandmarks()
//                         .withFaceExpressions();


//                     const emotions = detections.map((d) => d.expressions || {});
//                     const gazeDirection = calculateGazeDirection(detections); // Ensure this utility exists
//                     console.log("Gaze Direction:", gazeDirection);

//                     const posture = await detectPosture(poseDetector, video); // Ensure this utility exists
//                     // console.log("Current Time5a:", new Date().toLocaleTimeString());

//                     // **Behavioral Analysis**
//                     const microexpressions = detectMicroexpressions(detections); // Ensure this utility exists
//                     const gestures = await detectGestures(poseDetector, video); // Ensure this utility exists
//                     // console.log("Current Time5b:", new Date().toLocaleTimeString());

//                     // **Voice Analysis**
//                     analyser.getByteFrequencyData(audioDataArray);
//                     const volume = audioDataArray.reduce((a, b) => a + b, 0) / audioDataArray.length; // Average volume
//                     const pitch = Math.max(...audioDataArray); // Peak frequency amplitude
//                     // console.log("Current Time5c:", new Date().toLocaleTimeString());

//                     // **Engagement Metrics**
//                     const engagementScore = calculateEngagement(emotions, gestures); // Ensure this utility exists
//                     // console.log("Current Time6:", new Date().toLocaleTimeString());

//                     // Prepare analysis data
//                     const analysisData = {
//                         visual: { emotions, gazeDirection, posture },
//                         behavioral: { microexpressions, gestures },
//                         // voice: { pitch, volume },
//                         engagement: { score: engagementScore },
//                     };
//                     // console.log("Current Time7:", new Date().toLocaleTimeString());


//                     // console.log(analysisData)
//                     // Append analysis data
//                     currentAnalysis.current.push(analysisData);
//                     // console.log(`***************Stream active status: ${stream.active}`);


//                 } catch (error) {
//                     console.error("Error during analysis loop:", error);
//                 }
//             }, 2000); // Analyze every 800ms

//             // Cleanup resources on stream end
//             const stopAnalysis = () => {
//                 console.log("Inside Stopping analysis loop.");
//                 clearInterval(analysisLoop);
//                 video.pause();
//                 video.srcObject = null; // Clear the video source
//                 stream.getTracks().forEach((track) => track.stop());
//                 console.log("Stream ended. Stopping analysis loop.");
//             };

//         } catch (error) {
//             console.error("Error initializing real-time analysis:", error);
//         }
//     };




//     const calculateGazeDirection = (detections) => {
//         if (!detections || detections.length === 0) {
//             return "away"; // No face detected
//         }

//         const detection = detections[0]; // Assume single face for now
//         const landmarks = detection.landmarks;
//         if (!landmarks) {
//             return "away";
//         }

//         const leftEye = landmarks.getLeftEye();
//         const rightEye = landmarks.getRightEye();
//         const nose = landmarks.getNose();
//         const jaw = landmarks.getJawOutline();

//         if (!leftEye || !rightEye || !nose || !jaw) {
//             return "away"; // Missing key landmarks
//         }

//         // Calculate midpoints and angles
//         const eyeMidPoint = {
//             x: (leftEye[0].x + rightEye[3].x) / 2,
//             y: (leftEye[0].y + rightEye[3].y) / 2,
//         };
//         const nosePoint = nose[3]; // Approximate tip of the nose
//         const jawPoint = jaw[Math.floor(jaw.length / 2)]; // Center of the jawline

//         // Calculate vertical and horizontal displacement
//         const horizontalDisplacement = eyeMidPoint.x - nosePoint.x;
//         const verticalDisplacement = eyeMidPoint.y - jawPoint.y;

//         // Thresholds for determining directions
//         const horizontalThreshold = 10; // Adjust based on testing
//         const verticalThreshold = 10;

//         // Determine gaze direction based on displacement
//         if (horizontalDisplacement > horizontalThreshold) {
//             return "right";
//         } else if (horizontalDisplacement < -horizontalThreshold) {
//             return "left";
//         } else if (verticalDisplacement > verticalThreshold) {
//             return "up";
//         } else if (verticalDisplacement < -verticalThreshold) {
//             return "down";
//         } else {
//             return "center"; // Default to center
//         }
//     };



//     // const detectPosture = async (poseDetector, video) => {
//     //     const poses = await poseDetector.estimatePoses(video);
//     //     return poses.length > 0 && poses[0].keypoints[11].y - poses[0].keypoints[12].y > 20
//     //         ? "upright"
//     //         : "slouched";
//     // };


//     const detectPosture = async (poseDetector, video) => {
//         const poses = await poseDetector.estimatePoses(video);

//         if (!poses || poses.length === 0) {
//             return "undetected"; // No pose detected
//         }

//         const keypoints = poses[0].keypoints; // Assume single person for now
//         const leftShoulder = keypoints.find((point) => point.name === "left_shoulder");
//         const rightShoulder = keypoints.find((point) => point.name === "right_shoulder");
//         const leftHip = keypoints.find((point) => point.name === "left_hip");
//         const rightHip = keypoints.find((point) => point.name === "right_hip");

//         if (!leftShoulder || !rightShoulder || !leftHip || !rightHip) {
//             return "incomplete"; // Missing keypoints
//         }

//         // Calculate vertical alignment of shoulders and hips
//         const shoulderAlignment = Math.abs(leftShoulder.y - rightShoulder.y); // Difference in y-coordinates
//         const hipAlignment = Math.abs(leftHip.y - rightHip.y);

//         // Calculate horizontal alignment (leaning)
//         const shoulderLean = leftShoulder.x - rightShoulder.x;
//         const hipLean = leftHip.x - rightHip.x;

//         // Determine posture based on thresholds
//         const verticalThreshold = 20; // Adjust for sensitivity
//         const horizontalThreshold = 15;

//         if (shoulderAlignment > verticalThreshold) {
//             return "hunched"; // Shoulders misaligned vertically
//         } else if (hipAlignment > verticalThreshold) {
//             return "reclined"; // Hips misaligned vertically
//         } else if (shoulderLean > horizontalThreshold) {
//             return "leaning left"; // Shoulders leaning left
//         } else if (shoulderLean < -horizontalThreshold) {
//             return "leaning right"; // Shoulders leaning right
//         } else if (leftHip.y - rightHip.y > verticalThreshold) {
//             return "upright"; // Straight posture
//         } else {
//             return "slouched"; // Default fallback
//         }
//     };


//     const detectMicroexpressions = (detections) => {
//         // Placeholder logic for microexpressions
//         return "neutral";
//     };

//     // const detectGestures = async (poseDetector, video) => {
//     //     // Placeholder logic for gestures
//     //     return ["nodding"];
//     // };


//     const detectGestures = async (poseDetector, video) => {
//         const poses = await poseDetector.estimatePoses(video);

//         if (!poses || poses.length === 0) {
//             return ["no gesture detected"]; // Return if no pose is detected
//         }

//         const pose = poses[0]; // Assume a single person for simplicity
//         const keypoints = pose.keypoints;

//         if (!keypoints) {
//             return ["incomplete pose data"];
//         }

//         // Extract specific keypoints for gesture detection
//         const nose = keypoints.find((point) => point.name === "nose");
//         const leftShoulder = keypoints.find((point) => point.name === "left_shoulder");
//         const rightShoulder = keypoints.find((point) => point.name === "right_shoulder");
//         const leftElbow = keypoints.find((point) => point.name === "left_elbow");
//         const rightElbow = keypoints.find((point) => point.name === "right_elbow");

//         if (!nose || !leftShoulder || !rightShoulder || !leftElbow || !rightElbow) {
//             return ["incomplete keypoints"];
//         }

//         // Placeholder variables to track gestures
//         let gestures = [];

//         // Detect nodding (vertical movement of the nose)
//         if (nose.previousY && Math.abs(nose.y - nose.previousY) > 15) {
//             gestures.push("nodding");
//         }

//         // Detect shaking head (horizontal movement of the nose)
//         if (nose.previousX && Math.abs(nose.x - nose.previousX) > 20) {
//             gestures.push("shaking head");
//         }

//         // Detect waving (high movement of arms)
//         const leftWrist = keypoints.find((point) => point.name === "left_wrist");
//         const rightWrist = keypoints.find((point) => point.name === "right_wrist");

//         if (leftWrist && leftShoulder && leftWrist.y < leftShoulder.y) {
//             gestures.push("waving left hand");
//         }

//         if (rightWrist && rightShoulder && rightWrist.y < rightShoulder.y) {
//             gestures.push("waving right hand");
//         }

//         // Detect crossing arms (proximity of wrists to shoulders)
//         if (
//             leftWrist &&
//             rightWrist &&
//             Math.abs(leftWrist.x - rightWrist.x) < 50 &&
//             Math.abs(leftWrist.y - rightWrist.y) < 20
//         ) {
//             gestures.push("crossing arms");
//         }

//         // Track previous positions for temporal gesture detection
//         keypoints.forEach((point) => {
//             point.previousX = point.x;
//             point.previousY = point.y;
//         });

//         return gestures.length > 0 ? gestures : ["no gesture detected"];
//     };


//     const calculateEngagement = (emotions, gestures) => {
//         return emotions.length * 10 + gestures.length * 5;
//     };


//     // const calculateEngagement = (emotions, gestures) => {
//     //     // Weights for emotions based on assumed engagement impact
//     //     const emotionWeights = {
//     //       happy: 10,
//     //       surprised: 8,
//     //       neutral: 5,
//     //       sad: -5,
//     //       angry: -10,
//     //       fearful: -8,
//     //       disgusted: -6,
//     //     };

//     //     // Weights for gestures based on assumed engagement impact
//     //     const gestureWeights = {
//     //       nodding: 10,
//     //       shakingHead: -5,
//     //       waving: 8,
//     //       crossingArms: -8,
//     //       leaningForward: 12,
//     //       leaningBack: -12,
//     //     };

//     //     // Calculate emotion engagement
//     //     let emotionScore = 0;
//     //     for (const emotion of emotions) {
//     //       if (emotionWeights[emotion]) {
//     //         emotionScore += emotionWeights[emotion];
//     //       }
//     //     }

//     //     // Calculate gesture engagement
//     //     let gestureScore = 0;
//     //     for (const gesture of gestures) {
//     //       if (gestureWeights[gesture]) {
//     //         gestureScore += gestureWeights[gesture];
//     //       }
//     //     }

//     //     // Final engagement score
//     //     const engagementScore = emotionScore + gestureScore;

//     //     // Return a normalized engagement score
//     //     return Math.max(0, Math.min(engagementScore, 100)); // Ensure the score stays between 0 and 100
//     //   };




//     async function mergeAudioStreams(ttsAudioBlob, audioTracks) {
//         // Step 1: Create an AudioContext to handle the decoding and format conversion
//         const audioContext = new (window.AudioContext || window.webkitAudioContext)();

//         // Step 2: Decode the TTS audio blob into audio buffer
//         const ttsArrayBuffer = await ttsAudioBlob.arrayBuffer();
//         const ttsAudioBuffer = await audioContext.decodeAudioData(ttsArrayBuffer);

//         // Step 3: Get the sample rate, number of channels, and duration of the TTS audio
//         const ttsSampleRate = ttsAudioBuffer.sampleRate;
//         const ttsNumberOfChannels = ttsAudioBuffer.numberOfChannels;

//         // Step 4: Get the format of the webcam audio (audioTracks)
//         const webcamAudioContext = new (window.AudioContext || window.webkitAudioContext)();
//         const webcamSource = webcamAudioContext.createMediaStreamSource(new MediaStream(audioTracks));

//         const webcamSampleRate = webcamAudioContext.sampleRate;
//         const webcamNumberOfChannels = webcamSource.channelCount;

//         // Step 5: Check compatibility
//         let resampledTTSBuffer;
//         if (ttsSampleRate !== webcamSampleRate || ttsNumberOfChannels !== webcamNumberOfChannels) {
//             // If the formats don't match, resample the TTS audio to match the webcam audio format
//             const offlineContext = new OfflineAudioContext(
//                 webcamNumberOfChannels,
//                 ttsAudioBuffer.duration * webcamSampleRate,
//                 webcamSampleRate
//             );

//             // Create buffer source for TTS audio and connect to offline context
//             const ttsSource = offlineContext.createBufferSource();
//             ttsSource.buffer = ttsAudioBuffer;
//             ttsSource.connect(offlineContext.destination);
//             ttsSource.start();

//             // Render the resampled buffer
//             resampledTTSBuffer = await offlineContext.startRendering();
//         } else {
//             resampledTTSBuffer = ttsAudioBuffer; // No resampling needed
//         }

//         // Step 6: Create a destination for merging both tracks
//         const destination = audioContext.createMediaStreamDestination();

//         // Step 7: Connect both webcam audio and TTS audio to the destination
//         const ttsSourceNode = audioContext.createBufferSource();
//         ttsSourceNode.buffer = resampledTTSBuffer;
//         ttsSourceNode.connect(destination);

//         const webcamSourceNode = audioContext.createMediaStreamSource(new MediaStream(audioTracks));
//         webcamSourceNode.connect(destination);

//         // Play the TTS audio
//         ttsSourceNode.start();

//         // Step 8: Return the merged audio stream
//         return destination.stream; // This contains both TTS and webcam audio merged
//     }



//     async function streamImageInCornerAndMerge(imageSrc, canvasStream) {
//         try {
//             // Step 1: Create a canvas element and get its context
//             const canvas = document.createElement('canvas');
//             const ctx = canvas.getContext('2d');

//             ctx.imageSmoothingEnabled = true;
//             ctx.imageSmoothingQuality = 'high';

//             // Step 2: Set canvas dimensions to match the webcam video stream's dimensions
//             const videoTrack = canvasStream.getVideoTracks()[0];
//             const videoSettings = videoTrack.getSettings();
//             canvas.width = videoSettings.width;
//             canvas.height = videoSettings.height;

//             // Step 3: Create a video element to play the webcam stream
//             const videoElement = document.createElement('video');
//             videoElement.srcObject = canvasStream;
//             await videoElement.play();

//             // Step 4: Load the image to overlay
//             const image = new Image();
//             image.src = imageSrc;

//             await new Promise((resolve, reject) => {
//                 image.onload = resolve;
//                 image.onerror = reject;
//             });

//             // Step 5: Dynamically scale the image to fit within the canvas
//             const maxImageWidth = canvas.width * 0.3; // 30% of canvas width
//             const maxImageHeight = canvas.height * 0.3; // 30% of canvas height
//             let imageWidth = image.width;
//             let imageHeight = image.height;

//             if (imageWidth > maxImageWidth) {
//                 const scalingFactor = maxImageWidth / imageWidth;
//                 imageWidth = maxImageWidth;
//                 imageHeight *= scalingFactor;
//             }

//             if (imageHeight > maxImageHeight) {
//                 const scalingFactor = maxImageHeight / imageHeight;
//                 imageHeight = maxImageHeight;
//                 imageWidth *= scalingFactor;
//             }

//             // Step 6: Position the image in the corner
//             const imageX = 20; // 20px from the left
//             const imageY = 20; // 20px from the top

//             // Step 7: Animation loop to draw the video and the image onto the canvas
//             const updateCanvas = () => {
//                 ctx.clearRect(0, 0, canvas.width, canvas.height);

//                 // Draw the webcam video onto the canvas
//                 ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

//                 // Draw the scaled image onto the canvas
//                 ctx.drawImage(image, imageX, imageY, imageWidth, imageHeight);

//                 // Continue the animation loop
//                 requestAnimationFrame(updateCanvas);
//             };

//             updateCanvas();

//             // Step 8: Capture the canvas stream (which now contains the webcam video + image)
//             const imageStream = canvas.captureStream(30); // Capture at 30fps

//             // Step 9: Return the combined video stream
//             return imageStream;

//         } catch (error) {
//             console.error('Error while combining image and video stream:', error);
//             throw error;
//         }
//     }



//     const handleOnComplete = async (fullConversationText) => {
//         setIsListening(false);
//         setIsSpeaking(false);
//         setCountdownActive(false); // Stop the countdown when recognition completes
//         setIsWaiting(true);

//         if (recorderRef.current) {

//             if (recorderRef.current.getState() === 'recording') {
//                 recorderRef.current.stopRecording(async () => {
//                     const blob = recorderRef.current.getBlob();

//                     const uniqueId = uuidv4();  // Generate a unique identifier
//                     const filename = `videos/question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.mp4`;
//                     // const filename = `question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.webm`;
//                     const azurefilename = `videos/question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.webm`;

//                     // Save the initial response data and get the responsedataid
//                     const initialResponseData = {
//                         "questionnumber": CurrentQuestionNumber,
//                         "candidate": candidateId,
//                         "interview": interviewId,
//                         "questiontext": currentquestionstr.current,
//                         "response_text": fullConversationText,
//                         "response_time": responseStartTm.current,
//                         "questionstarttime": questionStartTm.current,
//                         "responseendtime": responseStartTm.current, // the variable is just passed for placeholder. actual responded time is filled at server side
//                         "usercompany": userCompany,
//                         "behavior": currentAnalysis.current
//                     };

//                     let responsedataid;
//                     try {
//                         const response = await axios.post(`${config.apiBaseUrl}/api/record_response/`, initialResponseData);
//                         responsedataid = response.data.response_id;
//                         //  console.log("Initial response saved successfully with ID:", responsedataid);
//                     } catch (error) {
//                         console.error("Error saving initial response:", error);
//                         return;
//                     }

//                     const isCloudflare = true; // Set this flag as needed (True for Cloudflare, False for Azure)
//                     // Merge the TTS audio stream with the recorded video blob before uploading
//                     if (isCloudflare) {
//                         uploadVideoToCloudflare(blob, responsedataid, filename)
//                             .then(cloudflareUrl => {
//                                 console.log("Upload completed");
//                             })
//                             .catch((error) => {
//                                 console.error("Error uploading video", error);
//                             });
//                     } else {
//                         // Upload to Azure Blob Storage
//                         uploadVideoToAzure(blob, azurefilename, responsedataid)
//                             .then(azureUrl => {
//                                 console.log("Upload completed and Azure URL obtained:");
//                             })
//                             .catch((error) => {
//                                 console.error("Error uploading video to Azure:", error);
//                             });
//                     }

//                     // Start next question immediately without waiting for the upload to complete
//                     setIsWaiting(false);
//                     setCurrentQuestionNumber(CurrentQuestionNumber + 1);
//                 });
//             }
//         }
//     };



//     function downloadBlob(blob, filename) {
//         // Create a URL for the blob
//         const url = URL.createObjectURL(blob);

//         // Create an anchor element
//         const a = document.createElement('a');
//         a.href = url;
//         a.download = filename;  // Set the file name

//         // Append the anchor to the body
//         document.body.appendChild(a);

//         // Programmatically click the anchor to trigger the download
//         a.click();

//         // Clean up by removing the anchor and revoking the object URL
//         document.body.removeChild(a);
//         URL.revokeObjectURL(url);
//     }



//     // this function gets a safe upload url from backend and then uploads the video to cloudflare    
//     async function uploadVideoToCloudflare(blob, responsedataid, filename) {
//         try {
//             // Step 1: Request a short-lived, time-limited upload URL from the backend

//             const response = await axios.post(`${config.apiBaseUrl}/api/get-cloudflare-upload-url/`, {
//                 interviewIdurl: interviewIdurl
//             });


//             // Step 2: Use the provided URL to upload the video file directly to Cloudflare
//             const uploadURL = response.data.upload_url;
//             const videoid = response.data.video_id;

//             const formData = new FormData();

//             formData.append("file", blob, filename);  // Pass the filename as the third argument

//             const uploadResponse = await axios.post(uploadURL, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 }
//             });

//             const updateResponseData = {
//                 "responsedataid": responsedataid,
//                 "video_url": videoid,
//                 "cloudflare": 1,
//                 "cloudflare_filename": filename
//             };
//             await axios.post(`${config.apiBaseUrl}/api/save_video_filename/`, updateResponseData);
//             // combinedStream.getTracks().forEach(track => track.stop());
//             // combinedStream = null;

//             // downloadBlob(blob, filename);
//             return videoid;

//         } catch (error) {
//             console.error("Error uploading video:", error);
//             throw error;
//         }
//     }



//     function uploadVideoToAzure(blob, filename, responsedataid) {
//         return axios.get(`${config.apiBaseUrl}/api/get_sas_token/`, { params: { blob_name: filename } })
//             .then(response => {
//                 const sasToken = response.data.sas_token;
//                 const azureUrl = `https://aiinterviewstorage.blob.core.windows.net/aiblobstorage/${filename}?${sasToken}`;
//                 //console.log("sasToken *******", sasToken)

//                 const config1 = {
//                     headers: {
//                         'x-ms-blob-type': 'BlockBlob',
//                         // 'Content-Type': blob.type,
//                         // 'Content-Type':  "video/mp4",
//                         'Content-Type': "video/webm",

//                     }
//                 };

//                 return axios.put(azureUrl, blob, config1)
//                     .then(() => {
//                         // Save the URL in the database after the upload completes
//                         //console.log("azureUrl *******", azureUrl)

//                         const updateResponseData = {
//                             "responsedataid": responsedataid,
//                             "video_url": azureUrl,
//                             "cloudflare": 0,
//                         };
//                         return axios.post(`${config.apiBaseUrl}/api/save_video_filename/`, updateResponseData)
//                             .then(() => azureUrl)
//                             .catch(error => {
//                                 console.error("Error updating video URL:", error);
//                                 throw error;
//                             });
//                     })
//                     .catch(error => {
//                         console.error("Error during file upload:", error);
//                         throw error;
//                     });
//             })
//             .catch(error => {
//                 console.error("Error getting SAS token:", error);
//                 throw error;
//             });
//     }





//     // this function inserts parameters into instructions // DO NOT DELETE. WILL NEED in future
//     // const formatTemplate = (template, values, wrapper = '') => {
//     //     return template.replace(/\${(.*?)}/g, (match, p1) => {
//     //         return values[p1] ? `${values[p1]}` : match;
//     //     });
//     // };


//     const formatTime = (seconds) => {
//         const mins = Math.floor(seconds / 60);
//         const secs = seconds % 60;
//         return 'Time Eapsed: ' && `${mins}:${secs < 10 ? '0' : ''}${secs}`;
//     };


//     const handleEndInterview = async () => {
//         const confirmEnd = window.confirm('Do you want to end the interview?');
//         if (confirmEnd) {

//             try {
//                 const response = await axios.post(`${config.apiBaseUrl}/api/end_interview/${interviewId}/`);
//                 // console.log(response.data.status);
//                 combinedStream.getTracks().forEach(track => track.stop());
//                 combinedStream = null;

//                 navigate('/thank-you'); // Redirect to thank you page
//             } catch (error) {
//                 console.error("Error ending interview:", error);
//             }
//         }
//     };


//     const handleCheckboxChange = () => {
//         setShowGuide(!showGuide); // Toggle the state of showGuide
//     };

//     const callChatAPI = async (userInput) => {

//         // console.log(userInput);


//         const responseData = {
//             instruction: userInput   // instruction is the variable chat api will look for. if instruction variable is not present chatapi will give respone 500
//         };


//         try {
//             const response = await axios.post(`${config.apiBaseUrl}/api/chat/`, responseData);

//             // console.log('Chat API response:', response.data);
//             return response.data.question;
//         } catch (error) {
//             console.error('Error calling chat API:', error);
//             return null;
//         }
//     };

//     const fetchQuestionInstruction = async (interviewId) => {
//         try {

//             const response = await axios.get(`${config.apiBaseUrl}/api/question_instruction/${interviewId}/`);
//             return response.data;
//         } catch (error) {
//             console.error('Error fetching question instruction:', error);
//             return null;
//         }
//     };



//     useEffect(() => {

//         let interval;
//         if (interviewStarted && !interviewEnded) {
//             interval = setInterval(() => {
//                 setTimeElapsed(prevTime => {
//                     if (prevTime + 1 >= 5400) { // 5400 seconds = 90 minutes
//                         // Action to take when 90 minutes have elapsed
//                         console.log('90 minutes have elapsed');
//                         combinedStream.getTracks().forEach(track => track.stop());
//                         combinedStream = null;
//                         navigate('/thank-you'); // Redirect to thank you page
//                     }
//                     return prevTime + 1;
//                 });
//             }, 1000);
//         }
//         return () => clearInterval(interval);
//     }, [interviewStarted, interviewEnded]);

//     const getVideoConstraints = () => {
//         if (isIphone) {
//             const isPortrait = window.innerHeight > window.innerWidth; // Check orientation
//             return isPortrait
//                 ? { width: 640, height: 360, aspectRatio: 3 / 3 } // Portrait constraints (3:4)
//                 : { width: 640, height: 360, aspectRatio: 9 / 9 }; // Landscape constraints (16:9)
//         }
//         return { width: 640, height: 360 }; // Default constraints for other devices
//     };

//     // Adjust CSS dynamically for iPhone
//     const webcamStyles = isIphone
//         ? {
//             position: 'absolute',
//             top: 1,
//             left: 1,
//             width: '100%', // Stretch to fit container
//             height: '100%', // Stretch to fit container
//             objectFit: 'contain', // Avoid stretching
//             transform: window.innerHeight > window.innerWidth ? 'scale(1)' : 'scale(0.5)', // Landscape fix
//         }
//         : {
//             position: 'absolute',
//             top: 1,
//             left: 1,
//             width: '320px',
//             height: '180px',
//             objectFit: 'cover', // Default behavior for non-iPhone
//         };


//     const setCountdown = (isActive) => {
//         // console.log('setCountdown triggerd');
//         setCountdownActive(isActive);
//         if (!isActive) {
//             // Additional actions when countdown is not active
//             setIsListening(true); // if we get setcountDown as false that means candidate spoke after countdown was shown. so we show the recording icon again
//         }
//     };


//     return (
//         <div className="interview-screen" style={{ backgroundColor: avatarbackgroundColor }}>
//             <img src={avatarimageOption} alt="Avatar" className="avatar" />
//             <Rnd
//                 default={{
//                     x: 1,
//                     y: 1,
//                     width: 320,
//                     height: 180,
//                 }}
//                 bounds="parent"
//             >
//                 <div className="webcam-container" style={{
//                     position: 'relative',
//                     width: '320px',
//                     height: '180px'
//                 }}>
//                     <Webcam
//                         audio={true} // Enable audio input for recording
//                         muted={true} // Mute for non-Safari browsers
//                         ref={webcamRef}
//                         videoConstraints={getVideoConstraints()} // Dynamic constraints for iPhone
//                         className="webcam"
//                         onUserMedia={() => console.log('Webcam loaded')}
//                         onUserMediaError={(err) => console.error('Webcam error:', err)}
//                         style={{
//                             position: 'absolute',
//                             top: 0,
//                             left: 0,
//                             width: '100%',
//                             height: '100%', // Adjust to fill the container
//                         }}
//                     />
//                     {showGuide && !interviewStarted && (
//                         <img
//                             src="/face-guide.webp" // Replace this path with your actual image path
//                             alt="Face Guide"
//                             style={{
//                                 position: 'absolute',
//                                 top: '45%',
//                                 left: '50%',
//                                 transform: 'translate(-50%, -50%)',
//                                 width: '165px',
//                                 height: '165px', // Set dimensions as needed
//                                 zIndex: 10 // Ensure this is higher than the webcam's z-index
//                             }}
//                         />
//                     )}
//                     <canvas
//                         ref={canvasRef}
//                         width={320} // Adjust for portrait and landscape modes
//                         height={180} // Adjust for portrait and landscape modes
//                         style={{
//                             position: 'absolute',
//                             top: 0,
//                             left: 0,
//                             width: '100%',
//                             height: '100%', // Adjust to fill the container
//                         }}
//                     />
//                 </div>
//             </Rnd>
//             {!interviewStarted && (

//                 <button
//                     onClick={handleStartInterview}
//                     className="start-interview-button"
//                     disabled={interviewStarted || isWebcamProblem}
//                 >
//                     Start Interview
//                 </button>
//             )}
//             <div
//                 className="question-container"
//             >
//                 {interviewStarted ? (
//                     <p>{currentquestionstr.current}</p>
//                 ) : (
//                     <p>{currentTextstr.current}</p>
//                 )}
//             </div>
//             {isSpeaking && (
//                 <div className="speaking-indicator">
//                     <img src="/speaking-icon.webp" alt="Speaking" className="blinking-icon" />
//                 </div>
//             )}
//             {isListening && !countdownActive && (
//                 <div className="listening-indicator">
//                     <img src="/listening-icon.webp" alt="Listening" className="blinking-icon" />
//                 </div>
//             )}
//             {countdownActive && (
//                 <div className="countdown-indicator">
//                     <CountdownTimer duration={4} onTimeout={() => setCountdownActive(false)} />
//                 </div>
//             )}
//             {isWaiting && (
//                 <div className="waiting-indicator">
//                     <img src="/waiting-icon.webp" alt="Waiting" className="blinking-icon" />
//                 </div>
//             )}
//             {interviewStarted && !interviewEnded && (
//                 <button
//                     onClick={handleEndInterview}
//                     className="end-interview-button"
//                     disabled={!interviewStarted} // Disable if interview hasn't started
//                 >
//                     End Interview
//                 </button>
//             )}


//             {!interviewStarted && (
//                 <select
//                     id="background-select"
//                     value={backgroundOption}
//                     className="background-select"
//                     disabled={interviewStarted}
//                     onChange={(e) => setBackgroundOption(e.target.value)}
//                 >
//                     <option value="none">No Camera Background</option>
//                     {!isSafari && <option value="blurred">Blurred</option>}
//                     <option value="office1">Office 1</option>
//                     <option value="office2">Office 2</option>
//                     <option value="beach">Beach</option>
//                 </select>
//             )}
//             {!interviewStarted && (

//                 <label className="show-guide" title="Toggle this to see where to position your face on the webcam. The guide will disappear when you will click Start Interview button">
//                     Enable Face Positioning Guide{"  "}
//                     <input
//                         type="checkbox"
//                         checked={showGuide}
//                         onChange={handleCheckboxChange}
//                         disabled={interviewStarted}
//                         title="Toggle this to see where to position your face on the webcam. The guide will disappear when you will click Start Interview button" // Tooltip on the checkbox as well
//                     />
//                 </label>
//             )}


//             {!interviewStarted && (

//                 <div
//                     style={{
//                         position: 'absolute',
//                         bottom: '280px',
//                         right: '30px',
//                         fontSize: '12px',
//                         color: 'white',
//                         width: '300px',
//                         textAlign: 'right'
//                     }}
//                 >
//                     By clicking 'Start Interview' button, you agree to our
//                     <a
//                         href="https://www.bitsoft.com/privacy-policy-and-candidate-agreement/"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         style={{ color: 'white', textDecoration: 'underline', marginLeft: '5px' }}
//                     >
//                         Terms and Conditions
//                     </a>
//                 </div>
//             )}

//             <div
//                 style={{
//                     position: 'absolute',
//                     top: '10px',
//                     right: '10px',
//                     fontSize: '18px',
//                     color: 'white',
//                     backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional: adds a semi-transparent background for better readability
//                     padding: '5px', // optional: adds padding around the text
//                     borderRadius: '5px', // optional: adds rounded corners
//                 }}
//             >
//                 Time elapsed: {formatTime(timeElapsed)}
//             </div>
//             <div
//                 style={{
//                     position: 'absolute',
//                     bottom: '5px',
//                     right: '10px',
//                     fontSize: '12px',
//                     color: 'white',
//                     padding: '5px',
//                 }}
//             >
//                 <a
//                     href="https://www.bitsoft.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     style={{ color: 'white', textDecoration: 'none' }}
//                 >
//                     Bitsoft International, Inc © 2025
//                 </a>
//             </div>
//         </div>
//     );
// };

// export default InterviewScreen;


