import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ duration, onTimeout }) => {
    const [secondsLeft, setSecondsLeft] = useState(duration);

    useEffect(() => {
        // Only start the timeout if there are seconds left
        if (secondsLeft > 0) {
            const timerId = setTimeout(() => {
                setSecondsLeft(secondsLeft - 1);
            }, 1000);

            // Clean up the timer when the component unmounts or the countdown changes
            return () => clearTimeout(timerId);
        } else {
            // Use a timeout to delay the onTimeout call until the progress bar update is visually completed
            const timeoutId = setTimeout(onTimeout, 850); // Delay onTimeout slightly to ensure the UI updates
            return () => clearTimeout(timeoutId);
        }
    }, [secondsLeft, onTimeout]);

    return (
        <div className="countdown-timer">
            <h3>Silence Detected</h3>
            <p>
                <div className="progress-bar-background">
                    <div className="progress-bar-fill" style={{ width: `${(secondsLeft / duration) * 100}%` }}></div>
                </div>
                Next Question in {secondsLeft}
            </p>
        </div>
    );
};

export default CountdownTimer;



// import React, { useEffect, useState } from 'react';

// const CountdownTimer = ({ duration, onTimeout }) => {
//     const [secondsLeft, setSecondsLeft] = useState(duration);

//     useEffect(() => {
//         if (secondsLeft === 0) {
//             onTimeout(); // Call the onTimeout function when the countdown finishes
//             return;
//         }

//         // Set up a timer to decrement the countdown each second
//         const timerId = setTimeout(() => {
//             setSecondsLeft(secondsLeft - 1);
//         }, 1000);

//         // Clean up the timer when the component unmounts or the countdown changes
//         return () => clearTimeout(timerId);
//     }, [secondsLeft, onTimeout]);

//     return (
//         <div className="countdown-timer">
//             <h3>Silence Detected</h3>
//             <p>
//             <div className="progress-bar-background">
//                 <div className="progress-bar-fill" style={{ width: `${(secondsLeft / duration) * 100}%` }}></div>
//             </div>
//             Next Question in {secondsLeft}</p>
//         </div>
//     );
// };

// export default CountdownTimer;