// Version 2.3

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import InterviewScreen from './components/InterviewScreen';
import ThankYouScreen from './components/ThankYouScreen';
import axios from 'axios';
import config from './config';
import InvalidLink from './components/InvalidLink'; 

// import InterviewScreen15 from './components/InterviewScreen15';


const App = () => {
    const [candidateId, setCandidateId] = useState(0);
    const [interviewId, setInterviewId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [initialData, setInitialData] = useState(null);
    const interviewIdurl = new URLSearchParams(window.location.search).get('lnk');
    // const navigate = useNavigate();

    function getBrowserInfo() {
        const browserInfo = {
          userAgent: navigator.userAgent,
          language: navigator.language,
          screenResolution: `${window.screen.width}x${window.screen.height}`,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
      
        // Check for the newer `userAgentData` API
        if (navigator.userAgentData) {
          browserInfo.platform = navigator.userAgentData.platform;
        } else {
          // Fallback to parsing `navigator.userAgent` for older browsers
          browserInfo.platform = navigator.userAgent.includes('Win')
            ? 'Windows'
            : navigator.userAgent.includes('Mac')
            ? 'MacOS'
            : navigator.userAgent.includes('Linux')
            ? 'Linux'
            : 'Unknown';
        }
      
        return browserInfo;
      }


    // console.log = function() {};

    const fetchURLData = async (interviewIdurl) => {
        try {
            if (interviewIdurl === null || interviewIdurl === "") {
                alert('Incorrect URL! Cannot proceed!');
                return;
            }

            const browserInfo = getBrowserInfo();
            const response = await axios.post(`${config.apiBaseUrl}/api/initial_urlcheck/${interviewIdurl}/`, browserInfo);

            setInitialData(response.data);
            setCandidateId(response.data.candidateidd);
            setInterviewId(response.data.interviewidd);
            if (!response.data || response.data.was_url_valid === null || response.data.was_url_valid === undefined) {
                alert("Could not retrieve interview information. Quitting!");
                return;
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching initial data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchURLData(interviewIdurl);
    }, [interviewIdurl]);


    if (loading) {
        return <div>Loading...</div>;
    }

    return (

        // the below code is for the interviewscreen15 to test the front end. do not remove it
    //     <div>
    //     <InterviewScreen15 />
    //     {/* Other components */}
    // </div>


        <Router>
            <Routes>
                {initialData.was_url_valid === 0 && <Route path="*" element={<Navigate to="/invalid-link" />} />}
                {initialData.interview_happened === 1 && <Route path="*" element={<Navigate to="/invalid-link" />} />}
                <Route path="/interview" element={<InterviewScreen interviewId={interviewId} candidateId={candidateId} interviewIdurl={interviewIdurl}/>} />
                <Route path="/thank-you" element={<ThankYouScreen />} />
                <Route path="/invalid-link" element={<InvalidLink />} /> 
                <Route path="*" element={<Navigate to="/interview" />} />
            </Routes>
        </Router>
    );
};

export default App;
